import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";
import ArticleCreateComponent from "./ArticleCreateComponent";
import Modal from "./Modal";
import {Image} from "./ButtonPublish";
import {showError} from "../actions/Error";
import {addPost} from "../actions/action";
import {toaster} from "./toast";

class CreateArticle extends Component {
    constructor(props) {
        super(props);
        this.state={
            isOpen:false,
            postStatus: {label:'Enable',value:'enable'},
            post_title: '',
            post_content: '',
            loading: false,
            selectedTagOption:[],
            tagOption:[]
        }
    }

    onHandleChange=(key,value)=>{
        this.setState({
            [key]:value
        })
    }

    handleSubmit=()=>{
        this.setState({
            loading:true
        },()=>{
            let tagList = this.state.selectedTagOption.map(a=>{ return a.value })
            let data={
                author_id:Auth.user().user_id,
                title:this.state.post_title,
                write_article:this.state.post_content,
                image:this.state.imagePreviewUrl,
                tag_list:tagList,
                url:this.state.url?this.state.url:'',
                post_status:this.state.postStatus.value,
            }
            addPost(data)
                .then((response)=>{
                    const sources = response.data;
                    this.setState({
                        isOpen:false,
                        loading:false
                    })
                    toaster.success('Post Created successfully')
                    if(this.props.getPostData){
                        this.props.getPostData()
                    }else{
                        window.location.href='/';
                        // this.props.history.push('/')
                    }
                }).catch((error)=>{
                showError(error)
                this.setState({
                    loading:false
                })
            });
        })
    }

    render() {
        return (
            <Modal
                size="lg"
                toggle={
                    <button type="button"
                            className="user-model mb-2"
                            onClick={()=>{
                                if (Auth.isLoggedIn()){
                                    this.setState({
                                        isOpen:true
                                    })
                                }else {
                                 Auth.login(true)
                                }
                            }}
                    >
                        <Image
                            image={Auth.user().avatar_url}
                            className="user-menu-img"
                        />
                        <span className="mind">{Auth.isLoggedIn()?'Report your case':'Please login to view this details Login Now'}</span>
                    </button>
                }
                isOpen={this.state.isOpen}
                className="user-model-dialog"
                title={"Create article"}
                style={{width: 700}}
                onClose={()=>{
                    this.setState({
                        isOpen:false
                    })
                }}
                footer={
                    <React.Fragment>
                        <button type="button"
                                className="btn cancle-btn"
                                onClick={()=>{
                                    this.setState({
                                        isOpen:false
                                    })
                                }}>Close</button>
                        <Button type="button"
                                intent="primary"
                                loading={this.state.loading}
                                className="btn create-btn"
                                onClick={this.handleSubmit}
                        >Create Post</Button>
                    </React.Fragment>
                }
            >
                <ArticleCreateComponent
                    onHandleChange={this.onHandleChange}
                    {...this.props}/>
            </Modal>
        );
    }
}

export default CreateArticle;
