import React,{ Component } from 'react';
import HomeContainer from './container/HomeContainer';

export default class Home extends Component{

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render(){

        return(
            <HomeContainer {...this.props} />

        )
    }
}
