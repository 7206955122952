
export const article = (sources) => {

    return {
        type: 'SET_ARTICLE',
        article:sources
    }
}

export const posts = (sources) => {

    return {
        type: 'SET_POSTS',
        posts:sources
    }
}

export const quickSaveArticle = (sources) => {

    return {
        type: 'SET_QUICK_SAVE_POSTS',
        posts:sources
    }
}

export const showAuthorDetail = (sources) => {

    return {
        type: 'SET_AUTHOR_DETAIL',
        author:sources
    }
}

export const setArticleDetail = (sources) => {

    return {
        type: 'SET_ARTICLE_DETAIL',
        details:sources
    }
}

export const setArticleComment = (sources) => {

    return {
        type: 'SET_ARTICLE_COMMENT',
        comments:sources
    }
}

export const setArticleDetailById = (sources) => {

    return {
        type: 'SET_ARTICLE_DETAILS',
        articleDetail:sources
    }
}

export const setAllTags = (sources) => {

    return {
        type: 'SET_ALL_TAGS',
        tags:sources
    }
}

export const setMyFollowTag = (sources) => {

    return {
        type: 'SET_MYFOLLOW_TAGS',
        myTags:sources
    }
}

export const setFollowingList = (sources) => {

    return {
        type: 'SET_FOLLOWING_LIST',
        following:sources
    }
}

export const setFollowerList = (sources) => {

    return {
        type: 'SET_FOLLOWER_LIST',
        follower:sources
    }
}
