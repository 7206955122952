import React, {Component} from 'react';
import Geocode from "react-geocode";
import PostList from './postList';
import TopBar from "../../../components/topBar";
import LoadingComponent from "../../../components/LoadingComponent";
import ArticleContainer from "../../../container/ArticleContainer";
import CreateArticle from "../../../components/CreateArticle";

export default class HomeComponent extends Component {

    constructor(props){
        super(props);
        this.state={
        }
    }

    componentDidMount(){
        this.props.getPostData('remove')
    }

    getData=(type)=>{
        if (type){
            this.props.getPostData(type)
        }else {
            this.props.getPostData()
        }
    }

    render() {

        if (this.props.posts===null){
            return (
                <LoadingComponent {...this.props}/>
            )
        }

        return (
            <React.Fragment>
                <div className="container">
                    <TopBar {...this.props} />
                    <div className="mt-5 mb-5">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <CreateArticle {...this.props}/>
                                    <ArticleContainer {...this.props}/>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 mt-2" >
                                    <PostList
                                        getData={this.getData}
                                        auth={false}
                                        {...this.props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        )
  }
}
