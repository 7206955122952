import React, { Component } from 'react';
import Select from 'react-select';
import TopBar from "../../../components/topBar";
import {showError} from "../../../actions/Error";
import CreateArticle from "../../../components/CreateArticle";
import ArticleContainer from "../../../container/ArticleContainer";
class FollowTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTagOption:[],
            tagOption:[]
        }
    }

    componentDidMount() {
        this.getTagList();
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    getTagList=()=>{

        this.props.getTagList()
            .then((response)=>{
                const sources = response.data;
                let tagOption = sources.map(a=>{ return{label:a.tag_name,value:a.tag_id} })
                this.setState({
                    tagOption:tagOption
                })
            })
            .catch((error)=>{
                showError(error)
            })
    }

    handleChange = selectedOption => {
        this.setState({ selectedTagOption:selectedOption });
    }

    handleSubmit=(event)=>{
        event.preventDefault();
        let tagList = this.state.selectedTagOption.map(a=>{ return a.value })
        let data={
            tag_id:tagList
        }
        this.props.addFollowingTag(data)
            .then((response)=>{
                const sources = response.data;
                this.props.history.push('/')
            }).catch((error)=>{
            showError(error)
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <TopBar {...this.props} />
                    <div className="mt-5 mb-5">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <CreateArticle {...this.props}/>
                                    <ArticleContainer {...this.props}/>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 mt-2" >
                                    <form className="form"
                                          onSubmit={this.handleSubmit}
                                          name="validation-form-demo"
                                          encType="multipart/form-data" noValidate="">
                                        <div className="card-body ">
                                            <div className="row">
                                                <div className="col-md-12  col-sm-12">
                                                    <div className="col-md-12 col-sm-12 card-body-space">
                                                        <div className="form-group floating-label">
                                                            <label className="display-block">Choose Your Following
                                                                Tag</label>
                                                            <Select
                                                                name={"following_tag"}
                                                                isMulti
                                                                className="select2-hidden-accessible"
                                                                value={this.state.selectedOption}
                                                                onChange={this.handleChange}
                                                                options={this.state.tagOption}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row card-body-space">
                                                <div className="col-md-6  col-xs-12 col-sm-4">
                                                    <div className="form-group floating-label">
                                                        <input type="submit"
                                                               className="btn btn-primary follow-btn follow-swa"
                                                               id="btn_publish" value="Follow Tag"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
            </React.Fragment>
        )
    }
}

export default FollowTagComponent;
