import {toaster} from "../components/toast";

export const showError=(error)=>{
    if (window.navigator.onLine){
        if (error.response === undefined){
            toaster.error("Something Went Wrong!");
        }else {
            if(422 === error.response.status) {
                Object.keys(error.response.data.errors).forEach((key)=>{
                    error.response.data.errors[key].forEach((error)=>{
                        toaster.error(error);
                    });
                });
            } else if (401 === error.response.status){
                toaster.error(error.response.data.message);
            }else{
                toaster.error('Something Went Wrong!');
            }
        }
    }else{
        toaster.error('No Internet Connection');
    }

}
