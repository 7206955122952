// import React, {Component} from 'react';
import {connect} from 'react-redux';

import ArticleEditComponent from '../component/ArticleEditComponent';
import {getArticleDetailById,updatePost,getTagList} from "../../../actions/action";
import {setArticleDetailById} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {

    return{
        articleDetail:state.article.articleDetail
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getArticleDetailById:(post_id)=>{
           return  getArticleDetailById(post_id)
            .then((response)=>{
                const sources = response.data;
                dispatch(setArticleDetailById(sources));
                return Promise.resolve(sources)
            }).catch((error)=>{
                showError(error)
                return Promise.reject(error)
            })
        },
        updatePost:(post_id,data)=>{
            return updatePost(post_id,data);
        },
        getTagList:()=>{
            return getTagList();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditComponent);
