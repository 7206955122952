import {connect} from 'react-redux';

import FollowTagComponent from '../component/FollowTagComponent';
import {followerList} from "../../../actions/action";
import {setFollowerList} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        follower:state.article.follower
    }
}

const mapDispatchToProps = dispatch => {

    return {
        followerList:(user_id)=>{
            followerList(user_id)
                .then((response)=>{
                    const sources = response.data;
                    dispatch(setFollowerList(sources))
                })
                .catch((error)=>{
                    dispatch(setFollowerList([]))
                    showError(error)
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowTagComponent);
