import React, {Component} from 'react';
import FollowTagContainer from "./container/FollowTagContainer";

class FollowTag extends Component {
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render() {
        return (
            <FollowTagContainer {...this.props} />
        );
    }
}

export default FollowTag;
