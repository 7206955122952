import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

// const img = {
//     display: 'block',
//     width: 'auto',
//     height: '100%'
// };

export const Previews=(props)=> {
    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            props.onChange(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div className="border">
        <span className="select-image-heading">Add image</span>
        <div className="artical-img-preview">
            <img
                src={file.preview}
            />
            <span className="bp3-icon bp3-icon-cross img-preview-close"
                  onClick={()=>{
                      setFiles([])
                      props.onChange([])
                  }}/>
        </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <React.Fragment>
            {
                props.imagePreviewUrl ?
                <div className="border">
                    <span className="select-image-heading">Add image</span>
                    <div className="artical-img-preview">
                        <img
                            src={props.imagePreviewUrl}
                        />
                        <span className="bp3-icon bp3-icon-cross img-preview-close"
                              onClick={() => {
                                  setFiles([])
                                  props.onChange([])
                              }}/>
                    </div>
                </div>
                :
                <React.Fragment>
                    {
                        files.length > 0 ?
                            (props.isUploadHidden === true ?
                                    ''
                                    :
                                    <div className="artical-title">
                                        <p {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <span className="add-image">Add image</span>
                                            <span className="choose float-right">Choose Image</span>
                                        </p>
                                    </div>
                            )
                            :
                            <div className="artical-title">
                                <p {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <span className="add-image">Add image</span>
                                    <span className="choose float-right">Choose Image</span>
                                </p>
                            </div>
                    }
                </React.Fragment>}
        </React.Fragment>
    );
}
export default Previews;
