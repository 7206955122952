import {connect} from 'react-redux';

import ViewFollowTagComponent from '../component/ViewFollowTagComponent';
import { followingList,addFollowingTag} from "../../../actions/action";
import { setFollowingList} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        following:state.article.following
    }
}

const mapDispatchToProps = dispatch => {

    return {
        followingList:(user_id)=>{
            followingList(user_id)
                .then((response)=>{
                    const sources = response.data;
                    dispatch(setFollowingList(sources))
                })
                .catch((error)=>{
                    showError(error)
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFollowTagComponent);
