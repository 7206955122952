import React, {Component} from 'react';
import { AnchorButton, Button, Classes, Code, Dialog,  H5, Intent, Switch, Tooltip } from "@blueprintjs/core";

export default class Modal extends Component{

    constructor(props){
        super(props);

        this.state={
          autoFocus: true,
          canEscapeKeyClose: true,
          canOutsideClickClose: true,
          enforceFocus: true,
          isOpen: false,
          usePortal: true,
        }
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      },()=>{
          if (this.props.onClose){
              this.props.onClose()
          }
      })
    }

    handleOpen=()=>{
      this.setState({
        isOpen: true
      })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }
    }

    render(){

        return(
            <React.Fragment>
              {
                this.props.toggle?
                  this.props.toggle:
                    <Button small={true} intent="primary" text="View"
                      className="btn-view mr-2"
                      onClick={() => this.handleOpen()}
                    />
              }
              <Dialog
                open={this.state.isOpen}
                className={this.props.className?this.props.className:''}
                onClose={this.handleClose}
                style={{
                    position:"absolute"
                }}
                {...this.props}
              >
                 <div className={Classes.DIALOG_BODY+'p-1 m-0 model-body-menu'} >
                     {this.props.children}
                 </div>
                 {
                   this.props.footer?
                   <div className={Classes.DIALOG_FOOTER}>
                      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        {this.props.footer}
                      </div>
                   </div>
                   :
                   ''
                 }
             </Dialog>
            </React.Fragment>
        );
    }

}
