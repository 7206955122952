import React, { Component } from 'react';
import Select from 'react-select';
import { Button } from '@blueprintjs/core';

import ArticleContainer from "../../../container/ArticleContainer";
import Auth from "../../../utils/Auth";
import TopBar from "../../../components/topBar";
import "react-datepicker/dist/react-datepicker.css";
import {showError} from "../../../actions/Error";
import Container from "../../../components/Container";
import {toaster} from "../../../components/toast";

export default class ArticleCreateComponent extends Component {

  constructor(props) {
    super(props);
    // const html = '';
    // const contentBlock = htmlToDraft(html);
    // if (contentBlock) {
    //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //   const editorState = EditorState.createWithContent(contentState);
    //
    // }
    this.state = {
      post_id:0,
      articleDetail: {},
      postStatus: {label:'Enable',value:'enable'},
      post_content: '',
      loading: false,
      selectedTagOption:[],
      tagOption:[]
    }

  }

  componentDidMount() {
    this.getTagList()
  }
  componentWillReceiveProps(nextProps) {

  }

  getTagList=()=>{

    this.props.getTagList()
        .then((response)=>{
          const sources = response.data;
          let tagOption = sources.map(a=>{ return{label:a.tag_name,value:a.tag_id} })
          this.setState({
            tagOption:tagOption
          })
        })
        .catch((error)=>{
          showError(error)
        })
  }

  handleChange = selectedOption => {
    this.setState({ selectedTagOption:selectedOption });
  }

  handlePostChange = selectedOption => {
    this.setState({ postStatus:selectedOption });
  }

  handleDateChange = post_content => {
    this.setState({ post_content:post_content });
  }

  onhandleChange=(e)=>{
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handleImageChange=(e)=> {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleSubmit=(event)=> {
    event.preventDefault();
    this.setState({
      loading:true
    },()=>{
      let tagList = this.state.selectedTagOption.map(a=>{ return a.value })
      let data={
        author_id:Auth.user().user_id,
        title:this.state.post_title,
        write_article:this.state.post_content,
        image:this.state.imagePreviewUrl,
        tag_list:tagList,
        post_status:this.state.postStatus.value,
      }
      this.props.addPost(data)
      .then((response)=>{
        const sources = response.data;
        this.props.history.push('/')
      }).catch((error)=>{
        showError(error)
        this.setState({
          loading:false
        })
      });
    })
  }

  render() {

    return (

      <React.Fragment>
        <Container {...this.props}>
          <div className="card card--shadow">
            <div className="card-header border-bottom p-15">
              <h4>Profile</h4>

            </div>
            <div className="card-body p-15">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12 text-left">
                  <form onSubmit={this.handleSubmit} encType={"form-data"}>
                    <div className="form-group floating-label">
                      <label htmlFor="txt_title" className="text-title">Title Here<span
                          className="form-required-field">*</span></label>
                      <input type="text"
                             className="form-control static dirty"
                             name="post_title"
                             id="txt_title"
                             value={this.state.post_title}
                             onChange={this.onhandleChange}
                             required=""
                      />
                    </div>
                    <div className="ptb-15 formating-image">
                      <img src={this.state.imagePreviewUrl}
                           id="articleImage" className="uplodeimage"/>
                    </div>
                    <div className="form-group floating-label mb-3"
                         bis_skin_checked="1">
                      <label htmlFor="txt_title" className="radio-label">Article Image</label>
                      <input type="file"
                             name={"post_image"}
                             id="profile_gallery"
                             data-parsley-max-file-size1=""
                             data-parsley-max-file-size="2048"
                             onChange={(e) => this.handleImageChange(e)}
                             className="formating-upload"/>
                    </div>
                    <div className="mb-5">
                      <label htmlFor="txt_title" className="radio-label">Write Article</label>
                      {/*<Editor*/}
                      {/*    editorState={this.state.post_content}*/}
                      {/*    toolbarClassName="toolbarClassName"*/}
                      {/*    wrapperClassName="wrapperClassName"*/}
                      {/*    editorClassName="editorClassName"*/}
                      {/*    onContentStateChange={this.handleDateChange}*/}
                      {/*/>*/}
                      <textarea
                          name="post_content"
                          rows={5}
                          placeholder="Post Content"
                          className="w-100 mt-2" onChange={this.onhandleChange}>{this.state.articleDetail.post_content}</textarea>
                    </div>
                    <div className="mb-4">
                      <label className="display-block">Add Tag<span
                          className="form-required-field">*</span></label>
                      <Select
                          name={"tag_list[]"}
                          isMulti
                          className="select2-hidden-accessible mb-3"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                          options={this.state.tagOption}
                      />
                    </div>
                    <div className="mb-lg-4 mt-4">
                      <label htmlFor="date-label" className="date-label">Status</label>
                      <Select
                          name={"post_status"}
                          className="select2-hidden-accessible mb-3"
                          value={this.state.postStatus}
                          onChange={this.handlePostChange}
                          options={[
                            {label:'Enable',value:'enable'},
                            {label:'Disable',value:'disable'},
                          ]}
                      />
                    </div>
                    <div className="text-right">
                      <button type="button"
                              className="btn btn-danger font-12 m-1"
                              onClick={()=>{ this.props.history.push('/') }}
                      >CANCLE</button>
                      <Button
                          type="submit"
                          loading={this.state.loading}
                          className="btn btn-primary m-1 font-12"
                      >CREATE POST</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
