import React, { Component } from 'react';

import {
  FavoriteBorder as FavoriteBorderIcon,
  BookmarkBorder,
  Visibility,
  ThumbUp,
  Report,
  Twitter,
  LocalOffer as TagFacesSharp
} from '@material-ui/icons';
import ArticleContainer from "../../../container/ArticleContainer";

export default class DraftArticleComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tripType: 0,
      selectedDate: new Date()
    }
  }

  componentDidMount() {
    this.props.getArticleData()
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.suggetionData);

  }

  render() {

    return (

      <React.Fragment>
        <div className="top_bar_content">
          <div className="mt-5 mb-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-xs-12">
                  <a href="#">
                    <div className="border p-15 text-left">
                      <h2 class="post-title mb-1">Recent and Updated News</h2>
                      <img src="https://s3.ap-south-1.amazonaws.com/gg-news/article_image/1596790584-18.jpg" class="card-body-img post-img" />
                    </div>
                  </a>
                </div>
                <ArticleContainer {...this.props} />
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </React.Fragment>
    );
  }
}
