
let initialState = {
    article: [],
    tags: [],
    myTags: [],
    following: null,
    follower: null,
    posts: null,
    quicksaveArticle: null,
    comments: [],
    details: null,
    articleDetail: [],
};
const renderArticle = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_POSTS':
            return {...state, posts: action.posts}
        case 'SET_QUICK_SAVE_POSTS':
            return {...state, quicksaveArticle: action.posts}
        case 'SET_ARTICLE':
            return {...state, article: action.article}
        case 'SET_ARTICLE_DETAIL':
            return {...state, details: action.details}
        case 'SET_ARTICLE_COMMENT':
            return {...state, comments: action.comments}
        case 'SET_ARTICLE_DETAILS':
            return {...state, articleDetail: action.articleDetail}
        case 'SET_ALL_TAGS':
            return {...state, tags: action.tags}
        case 'SET_MYFOLLOW_TAGS':
            return {...state, myTags: action.myTags}
        case 'SET_FOLLOWING_LIST':
            return {...state, following: action.following}
        case 'SET_FOLLOWER_LIST':
            return {...state, follower: action.follower}
        default:
            return state
    }
}

export default renderArticle
