import React, {Component} from 'react';
import { Tab, Tabs, Button } from "@blueprintjs/core";
import {
    LibraryBooks,
    Info,
    Bookmark,
    LocalOffer
} from '@material-ui/icons';

import TopBar from "../../../components/topBar";
import {Auth} from 'gg-react-utilities';
import LoadingComponent from "../../../components/LoadingComponent";
import PostListing from "./PostList";
import AuthorDetail from "./AuthorDetail";
import MyProfile from "../../MyProfile";
import AllTags from "../../AllTags";
import QuickSaveArticle from "../../QuickSaveArticle";
import {ButtonFollow} from "../../../components/ButtonPublish";

class AuthorDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            author:null,
            author_id:null,
            disableTabs:false,
            selectedTabIndex:"pl"
        }
    }

    componentDidMount(){
        this.setState({
            author_id:this.props.location.state.author_id
        },()=>{
            this.handleTabChange("pl")
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.author.length>0){
            let disableTabs=true;
            if (nextProps.author[0].sso_user_id===Auth.user().user_id){
                disableTabs=false;
            }
            this.setState({
                author:nextProps.author[0],
                disableTabs:disableTabs
            })
        }
        if (this.state.author_id!==nextProps.location.state.author_id){
            window.location.reload()
        }
    }

    getAuthorArticleData=(type="update")=>{
        try {
            let author_id = this.props.location.state.author_id;
            if (author_id){
                this.props.showAuthorById(author_id,type)
                this.props.getQuickArticleList(author_id,type)
            }
        }catch (e) {
            this.props.history.push('/')
        }
    }

    handleTabChange=(selectedTabIndex)=>{
        this.setState({
            selectedTabIndex:selectedTabIndex
        })
        this.getAuthorArticleData('remove')
    }

    render(){
        if (this.state.author===null){
            return (
                <LoadingComponent {...this.props}/>
            )
        }

        return (
            <React.Fragment>
                <div className="container">
                    {
                        Auth.isLoggedIn()?
                            <TopBar titleClass="title-welcome" hideLogo={true} title={"Welcome "+Auth.user().fname} {...this.props} />
                            :
                            <TopBar titleClass="title-welcome" hideLogo={true} title={"Welcome "} {...this.props} />
                    }

                    <AuthorDetail author={this.state.author}
                                  getAuthorArticleData={this.getAuthorArticleData}
                    />
                    <div className="ptb-20 tab-list-author">
                        <Tabs id="TabsExample"
                              className="nav nav-tabs nav-tabs-swatantra"
                              onChange={this.handleTabChange}
                              style={{width:"10px"}}
                              selectedTabId={this.state.selectedTabIndex}>
                            <Tab id="pl"
                                 title={
                                     <div className="tab-item">
                                         <LibraryBooks/>
                                         <span>Posts</span>
                                     </div>}
                                 panel={<PostListing
                                     authorDetail={this.state.author}
                                     posts={this.props.posts}
                                     getData={this.getAuthorArticleData}
                                     {...this.props}/>}
                                 panelClassName="nav-item" />
                            <Tab id="ab"
                                 title={
                                     <div className="tab-item">
                                         <Info/>
                                         <span>About</span>
                                     </div>}
                                 panel={<MyProfile authorDetail={this.state.author} {...this.props} />}
                                 panelClassName="nav-item" />
                            <Tab id="mb"
                                 title={
                                     <div className="tab-item">
                                         <Bookmark/>
                                         <span>My Bookmarks</span>
                                     </div>}
                                 disabled={this.state.disableTabs}
                                 panel={<QuickSaveArticle authorDetail={this.state.author}  {...this.props}/>}
                                 panelClassName="nav-item" />
                            <Tab id="mf"
                                 title={
                                     <div className="tab-item">
                                         <LocalOffer/>
                                         <span>My Follow Tags</span>
                                     </div>}
                                 disabled={this.state.disableTabs}
                                 panel={<AllTags authorDetail={this.state.author} {...this.props}/>}
                                 panelClassName="nav-item" />
                        </Tabs>
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        )
    }
}

export default AuthorDetailComponent;
