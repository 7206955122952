import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";
import ArticleCreateComponent from "./ArticleCreateComponent";
import Modal from "./Modal";
import {Image} from "./ButtonPublish";
import {showError} from "../actions/Error";
import {updatePost} from "../actions/action";
import {toaster} from "./toast";

class EditArticle extends Component {
    constructor(props) {
        super(props);
        this.state={
            isOpen:false,
            postStatus: {label:'Enable',value:'enable'},
            post_title: '',
            post_content: '',
            loading: false,
            selectedTagOption:[],
            Tag:[],
            tagOption:[]
        }
    }

    componentDidMount() {
        this.setState({
            imagePreviewUrl:this.props.editData.Image,
            post_content:this.props.editData.post_content,
            post_title:this.props.editData.post_title,
            url:this.props.editData.url,
            Tag:this.props.editData.tag_id,
            articleDetail:this.props.editData
        })
    }

    onHandleChange=(key,value)=>{
        this.setState({
            [key]:value
        })
    }

    handleSubmit=()=>{
        this.setState({
            loading:true
        },()=>{
            let tagList = this.state.selectedTagOption.map(a=>{ return a.value })
            let data={};
            if (Auth.isLoggedIn()){
                data.author_id=Auth.user().user_id;
            }
            data.title=this.state.post_title;
            data.write_article=this.state.post_content;
            if (this.state.imagePreviewUrl){
                data.image=this.state.imagePreviewUrl;
            }
            if(tagList.length>0){
                data.tag_list=tagList;
            }else {
                data.tag_list=this.state.Tag;
            }
            data.post_status=this.state.postStatus.value;
            data.url=this.state.url?this.state.url:'';
            updatePost(this.props.editData.post_id,data)
                .then((response)=>{
                    const sources = response.data;
                    this.setState({
                        loading:false
                    })
                    toaster.success('Post updated successfully')
                    if(this.props.getPostData){
                        this.props.getPostData()
                        this.props.onClose()
                    }else{
                        window.location.href='/';
                        // this.props.history.push('/')
                    }
                }).catch((error)=>{
                    showError(error)
                    this.setState({
                        loading:false
                    })
                });
        })
    }

    render() {
        return (
            <Modal
                size="lg"
                toggle={<div/>}
                isOpen={this.props.isOpen}
                className="user-model-dialog"
                title={"Edit article"}
                style={{width: 700}}
                onClose={this.props.onClose}
                footer={
                    <React.Fragment>
                        <button type="button"
                                className="btn cancle-btn"
                                onClick={this.props.onClose}>Close</button>
                        <Button type="button"
                                intent="primary"
                                loading={this.state.loading}
                                className="btn create-btn"
                                onClick={this.handleSubmit}
                        >Edit Post</Button>
                    </React.Fragment>
                }
            >
                <ArticleCreateComponent
                    editData={this.props.editData}
                    onHandleChange={this.onHandleChange}
                    {...this.props}/>
            </Modal>
        );
    }
}

export default EditArticle;
