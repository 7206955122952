// import React, {Component} from 'react';
import {connect} from 'react-redux';

import AllTagsComponent from '../component/AllTagsComponent';
import {getTagList,tagfollow,getMyFollowTag} from "../../../actions/action";
import {setAllTags,setMyFollowTag} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {

    return{
        tags:state.article.myTags,
        myTags:state.article.myTags,
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getMyFollowTag:()=>{
            getMyFollowTag()
            .then((response)=>{
                const sources = response.data;
                dispatch(setMyFollowTag(sources))
            }).catch((error)=>{
                showError(error)
            })
        },
        tagfollow:(data)=>{
            return tagfollow(data)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllTagsComponent);
