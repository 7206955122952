import React, { Component } from 'react';
import ArticleContainer from "../../../container/ArticleContainer";
import TopBar from '../../../components/topBar';
import Auth from '../../../utils/Auth';
import moment from "moment";
import {toaster} from "../../../components/toast";
import {
    Button,
    ButtonFollow
} from "../../../components/ButtonPublish";
import LoadingComponent from "../../../components/LoadingComponent";
import PostList from "../../Home/component/postList";
import CreateArticle from "../../../components/CreateArticle";
import EmptyComponent from "../../../components/EmptyComponent";

export default class ArticleDetailsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      post_id: 0,
      follow:1,
      post_title:'loading...',
      comment_content:''
    }
  }

  componentDidMount() {
    try{
      let post_id = this.props.match.params.post_id;
      this.setState({
        post_id:post_id
      },()=>{
        this.getPostArticleData()
      })
    }catch (e) {
      this.props.history.push('/')
    }
  }

  componentWillReceiveProps(nextProps) {
      if(nextProps.details != null && nextProps.details.length > 0) {
          this.setState({
              follow: nextProps.details[0]['user_following'],
              post_title: nextProps.details[0]['post_title']
          })
      }
  }

  getPostArticleData=()=>{
    let post_id = this.state.post_id;
    if (post_id){
      // this.props.getAuthorArticleData(author_id)
      this.props.getArticleDetail(post_id)
      this.props.getArticleComment(post_id)
    }
  }

    addcomment=(post_id)=>{
        if (this.state.comment_content){
            this.props.addcomment(post_id,this.state.comment_content)
            .then((response)=>{
                const source = response.data;
                this.setState({
                    comment_content:''
                })
                toaster.success('Comment Add Successfully!');
                this.getPostArticleData()
            }).catch((errors)=>{
                toaster.error('Something Went Wrong!');
            });
        }else {
            toaster.error('Please Enter Comment Text')
        }

      }

  render() {

    if (this.props.details == null && this.props.details == undefined){
      return (
          <LoadingComponent {...this.props}/>
      );
    }

      return (
          <React.Fragment>
              <div className="container">
                  <TopBar {...this.props} />
                  <div className="mt-5 mb-5">
                      <div className="">
                          <div className="row">
                              {/*<div className="col-md-12 col-sm-12 col-xs-12">*/}
                              {/*    <CreateArticle {...this.props}/>*/}
                              {/*    <ArticleContainer {...this.props}/>*/}
                              {/*</div>*/}
                              {/*<div className="col-md-12 col-sm-12 col-xs-12 mt-2" >*/}
                              {/*    <PostList*/}
                              {/*        getData={this.getPostArticleData}*/}
                              {/*        posts={this.props.details}*/}
                              {/*        readMore={true}*/}
                              {/*        auth={false}*/}
                              {/*        {...this.props}/>*/}
                              {/*</div>*/}
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                  <PostList
                                      getData={this.getPostArticleData}
                                      posts={this.props.details}
                                      readMore={true}
                                      auth={false}
                                      {...this.props}/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="clearfix"></div>
          </React.Fragment>
      )
    return (
      <React.Fragment>
          <div className="container">
              <TopBar title={"View Article"} {...this.props} />
              <div className={""}>
                  {
                      this.props.details.map((value,key)=>{
                          let Author_Image = value.Author_Image?value.Author_Image:'https://news.globalgarner.in/img/Profile_Fill.png'
                          let Tag = value.Tag?value.Tag.split(','):[];
                          return(
                              <React.Fragment key={key}>
                                  <PostList
                                      detail={true}
                                      posts={this.props.details}
                                      {...this.props}
                                  />
                                  <div className="card card--shadow mt-3 mb-3">
                                      <div className="card-header">
                                          <div className="row">
                                              <div className="col-md-6 col-sm-6 col-xs-12 col-6">
                                                  <div className="card-header-menu">
                                                      <div className="uaser d-flex">
                                                          <img className="user-icon mr-1 mb-3" src={Author_Image}/>
                                                          <div className="">
                                                              <span className="card-primary-color publisher_name">{value.Author}</span> <br/>
                                                              <small>{moment(value.created_At).format('Do MMMM YYYY')}</small>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-md-6 col-sm-6 col-xs-12 col-6 text-right">
                                                  {
                                                      value.author_id != Auth.user().user_id?
                                                          <ButtonFollow
                                                              type="button"
                                                              loading={false}
                                                              author_id={value.author_id}
                                                              user_following={value.user_following===0?false:true}
                                                              getPostArticleData={()=>{ this.getPostArticleData() }}
                                                          />
                                                          :
                                                          <a type="button" className="btn btn-primary" onClick={()=>{ this.props.history.push('/my-profile') }}>My Profile</a>
                                                  }

                                              </div>
                                          </div>
                                      </div>
                                      {
                                          value.about_author?
                                              <div className="card-body p-15">
                                                  {value.about_author}
                                              </div>
                                              :''
                                      }

                                  </div>
                                  <div className="card card--shadow mb-5">
                                      <div className="p-15">
                                          <div className="row">
                                              <div className="col-md-1 col-sm-1 col-1 text-left">
                                                  <img className="user-icon mb-3" src="https://news.globalgarner.in/img/Profile_Fill.png"/>
                                              </div>
                                              <div className="col-md-11 col-sm-11 col-11 text-left mb-3">
                                              <textarea
                                                  className="w-100"
                                                  name={"comment_content"}
                                                  placeholder="Write a Response"
                                                  className="textarea"
                                                  value={this.state.comment_content}
                                                  onChange={(event)=>{
                                                      this.setState({
                                                          [event.target.name]:event.target.value
                                                      })
                                                  }}></textarea>
                                              </div>
                                              <div className="col-md-1 col-sm-1 col-1 text-left"></div>
                                              <div className="col-md-11 col-sm-11 col-11 text-left">
                                                  <Button type="button"
                                                          loading={false}
                                                          comment_content={this.state.comment_content}
                                                          post_id={value.post_id}
                                                          className={"bp3-button bp3-intent-success"}
                                                          getPostArticleData={()=>{
                                                              this.setState({
                                                                  comment_content:''
                                                              })
                                                              this.getPostArticleData(value.author_id)
                                                          }}
                                                  >PUBLISH</Button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </React.Fragment>
                          )
                      })
                  }
                  <div className="card ">
                      {
                          this.props.comments.map((value,index)=>{
                              let Author_Image = value.Author_Image?value.Author_Image:'https://news.globalgarner.in/img/Profile_Fill.png'
                              return(
                                  <div className="card card--shadow mt-5" key={index}>
                                      <div className="card-header">
                                          <div className="row">
                                              <div className="col-md-12 col-sm-12 col-xs-12">
                                                  <div className="card-header-menu">
                                                      <div className="uaser d-flex">
                                                          <img className="user-icon mr-3 mb-3"
                                                               src={Author_Image}/>
                                                          <div className="">
                                                    <span
                                                        className="card-primary-color publisher_name mb-3">{value.Display_Name}</span>
                                                              <br/>
                                                              <small className={"mt-3"}>{moment(value.created_At).format('Do MMMM YYYY')}</small>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="card-body p-15">
                                          <p className={"text-break"}>{value.comment_content}</p>
                                      </div>
                                  </div>
                              );
                          })
                      }
                  </div>

              </div>
          </div>
      </React.Fragment>
    );
  }
}
