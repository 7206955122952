import React, {Component} from 'react';
import FollowTagContainer from "./container/FollowTagContainer";

class FollowTag extends Component {
    render() {
        return (
            <FollowTagContainer {...this.props} />
        );
    }
}

export default FollowTag;
