import React, {Component} from 'react';
import TopBar from "./topBar";
import LoaderImg from "../images/100x100-Loader.gif";

class LoadingWithOutTopBarComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={LoaderImg} className="img-fluid mt-5" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingWithOutTopBarComponent;
