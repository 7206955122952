import React, { Component } from 'react';
import {Auth} from 'gg-react-utilities';
import TopBar from "../../../components/topBar";
import LoadingComponent from "../../../components/LoadingComponent";
import EmptyComponent from "../../../components/EmptyComponent";
import {ButtonFollow, Image} from "../../../components/ButtonPublish";

class ViewFollowTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            author_id:0,
            title:"Followers"
        }
    }

    componentDidMount() {
        try {
            // console.log(this.props.match.params)
            let author_id = this.props.match.params.author_id;
            this.setState({
                author_id:author_id
            },()=>{
                this.getfollowingListById()
            })
        }catch (e) {
            this.props.history.goBack()
        }
    }

    getfollowingListById=()=>{
        let author_id = this.state.author_id;
        if (author_id){
            this.props.followingList(author_id)
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedTagOption:selectedOption });
    }

    render() {

        if (this.props.following===null){

            return (
                <LoadingComponent {...this.props}/>
            )
        }

        if (this.props.following.length<=0) {
            return (
                <div className="container">
                    <TopBar hideLogo={true} title={this.state.title} {...this.props} />
                    <EmptyComponent
                        message={"Followers Not Available"}
                        title={"Swatantra"}
                        {...this.props}
                    />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar hideLogo={true} title={this.state.title} {...this.props} />
                    <div className="row mt-3">
                        {
                            this.props.following.map((value, index)=>{

                                return(
                                    <div className="col-md-6 col-sm-6 col-xs-12 mb-3 mt-3" key={index}>
                                        <div className="border-menu p-3 mb-4 clearfix">
                                            <div className="float-left">
                                                <img
                                                    src={value.Author_Image}
                                                    className="user-menu-img d-inline-block" />
                                                    <p className="follow-swa d-inline-block">{value.display_name}</p>
                                            </div>
                                            {
                                                value.id===Auth.user().user_id?
                                                    ''
                                                    :
                                                    <ButtonFollow
                                                        className="btn follow-btn follow-swa float-right"
                                                        loading={false}
                                                        user_following={value.is_follow}
                                                        author_id={value.id}
                                                        getPostArticleData={this.getfollowingListById}
                                                    />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        )
    }
}

export default ViewFollowTagComponent;
