import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {NoLogin} from 'gg-react-utilities';
import Auth from './utils/Auth';

import '../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
// import "./style/gg-styles.css";

import reducers from './reducers';
import Home from './pages/Home';
import AuthorDetail from './pages/AuthorDetail';
import ArticleDetails from './pages/ArticleDetails';
import ArticleEdit from './pages/ArticleEdit';
import ArticleCreate from './pages/ArticleCreate';
import AllTags from './pages/AllTags';
import QuickSaveArticle from './pages/QuickSaveArticle';
import DraftArticle from './pages/DraftArticle';
import MyProfile from './pages/MyProfile';
import FollowTag from './pages/FollowTag';
import FollowTagList from './pages/FollowTagList';
import TagArticleView from './pages/TagArticleView';
import ViewFollowTag from './pages/ViewFollowTag';
import App from './App';
import {Notfound,Maintance,ScrollTop,GgUtils} from 'gg-react-utilities';
import { register as unregisterServiceWorker } from './registerServiceWorker'
import About from "./pages/About";
import {packageConfigure} from "./Config";

const customMiddleWare = store => next => action => {
    next(action)
}

// Auth.logout()
const createStoreWithMiddleware = applyMiddleware(customMiddleWare)(createStore);

if (process.env.REACT_APP_ENV === 'PRODUCTION'){

    GgUtils.sentryScripts(process.env.REACT_APP_SENTRY_URL);

}

if (process.env.REACT_APP_ENV === 'PRODUCTION'){
    GgUtils.googleAnalyticalScripts(process.env.REACT_APP_GOOGLE_AUTHENTICATE_CODE);
}

ReactDOM.render(
    <React.Fragment>
        <Provider store={createStoreWithMiddleware(reducers)}>
            <Router>
              <App>
                  <div className="mycitymyvoucher">
                      {
                        process.env.REACT_APP_IS_MAINTENANCE_MODE===true || process.env.REACT_APP_IS_MAINTENANCE_MODE==="true"?
                        <Switch>
                          <Route path="*" component={Maintance}/>
                        </Switch>
                        :
                        <Switch>
                            <Route exact path='/' component={Home}/>
                            <Route path='/author-detail/:auth_name' component={AuthorDetail}/>
                            <Route path='/article/:auth_name/:post_id' component={ArticleDetails}/>
                            <Route path='/article-edit' component={ArticleEdit}/>
                            <Route path='/article-create' component={ArticleCreate}/>
                            <Route path='/my-profile' component={MyProfile}/>
                            <Route path='/all-tags' component={AllTags}/>
                            <Route path='/quicksave-article' component={QuickSaveArticle}/>
                            <Route path='/draft-article' component={DraftArticle}/>
                            <Route path='/tag-following-list/:author_id/:name' component={FollowTag}/>
                            <Route path='/tag-following' component={FollowTagList}/>
                            <Route path='/tag/:tag_name' component={TagArticleView}/>
                            <Route path='/view-following/:author_id/:name' component={ViewFollowTag}/>
                            <Route path='/about-us' component={About}/>
                            <Route path="*" component={Notfound}/>
                        </Switch>
                      }
                  </div>
                  <ScrollTop/>
                </App>
            </Router>
        </Provider>
    </React.Fragment>,
    document.querySelector('#root'));
    unregisterServiceWorker();
