import {connect} from 'react-redux';
import AboutComponent from '../component/AboutComponent';

const mapStateToProps = state => {

    return{
    }
}

const mapDispatchToProps = dispatch => {

    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutComponent);
