import React, {Component} from 'react';
import ViewFollowTagContainer from "./container/ViewFollowTagContainer";

class ViewFollowTag extends Component {
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render() {
        return (
            <ViewFollowTagContainer {...this.props} />
        );
    }
}

export default ViewFollowTag;
