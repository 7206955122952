import React,{ Component } from 'react';
import AllTagsContainer from './container/AllTagsContainer';

export default class AllTags extends Component{
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render(){

        return(
            <React.Fragment>
                <AllTagsContainer {...this.props} />
            </React.Fragment>

        )
    }
}
