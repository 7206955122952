import React, {Component} from 'react';
import TopBar from "../../../components/topBar";
import GGLOGO from '../../../images/GG_Logo.svg';
import Swatantra_logo from '../../../images/Swatantra_logo.svg';

class AboutComponent extends Component {
    render() {
        return (
            <div className="container">
                <TopBar {...this.props} aboutTitle={"About Swatantra"}/>
                <div className="mt-5 mb-5">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                <img className={"img-responsive about-image"}
                                     src={GGLOGO}
                                />
                                <img className={"img-responsive about-image"} src={Swatantra_logo}/>
                            </div>
                        </div>
                        <div className="row">
                            <p className="text-justify about-content" style={{"line-height":"30px"}}>
                                Swatantra is a part of our Corporate Social Responsibility and a platform to serve the needy. It provides Free Legal Aid to the people in need of justice and to those who can't afford high legal expenses. Through this medium we aim to provide Justice to the people and to fight against prevailing injustice in the society. We sincerely believe that Injustice anywhere is a threat to justice everywhere, therefore we have stepped forward to provide you legal advice and  legal help.
                                It is a program to give exclusively Free Legal Advice from our Legal Team. It may include
                                Advice and guidance of Filing of Criminal and Civil Cases and advocate fees. It is a help without any commercial interest, for the ordinary people who can't afford legal expenses burden and have suffered due to injustice.
                                If your case has gravity and is of serious nature, it will be selected by our legal team and our user for delivering legal advice and in such cases advocate fees and legal advice will be paid by us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutComponent;
