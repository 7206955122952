import {connect} from 'react-redux';

import FollowTagComponent from '../component/FollowTagComponent';
import { getTagList,addFollowingTag} from "../../../actions/action";

const mapStateToProps = state => {
    return{

    }
}

const mapDispatchToProps = dispatch => {

    return {
        getTagList:()=>{
            return getTagList();
        },
        addFollowingTag:(data)=>{
            return addFollowingTag(data);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowTagComponent);
