import React, {Component} from 'react';
import renderHTML from "react-render-html";

class ReadMoreContent extends Component {
    constructor(props) {
        super(props);
        this.state={
            readMore:true,
            content:"More"
        }
    }

    more=()=>{
        this.setState({
            readMore:false,
            content:"less"
        })
    }

    less=()=>{
        this.setState({
            readMore:true,
            content:"More"
        })
    }

    render() {
        return (
            <React.Fragment>
                <p className={this.props.readMore?"":"post-content-wrap mb-3"}>
                    {renderHTML(this.props.post_content)}
                </p>
                {
                    this.props.url?
                        <a href={this.props.url}
                           className={"post-content-wrap mb-3"}
                           target="_blank"
                        >
                            {this.props.url}
                        </a>
                        :
                        ''
                }
                <div className="mb-3 clearfix">
                    {
                        !this.props.readMore?
                            <a href={'/article/' + this.props.Author+'/'+this.props.post_id}
                               className="read-more"
                            >Read More</a>
                            :''
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default ReadMoreContent;
