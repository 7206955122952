import React, {Component} from 'react';
import {Menu,
    MenuItem,
    Popover,
    Position
} from "@blueprintjs/core";
import {MoreHoriz} from "@material-ui/icons";
import {Auth} from "gg-react-utilities";
import {showError} from "../../../actions/Error";
import {reportAbuseAuthor,deletePost} from "../../../actions/action";
import EditArticle from "../../../components/EditArticle";
import {toaster} from "../../../components/toast";

class PostMenu extends Component {
    constructor(props) {
        super(props);
        this.state={
            isOpen:false
        }
    }

    reportAbuseAuthor=(post_id)=>{
        reportAbuseAuthor(post_id)
            .then((response)=>{
                const sources = response.data;
                toaster.success('Article reported successfully')
                this.props.getPostData('remove')
            })
            .catch((error)=>showError(error))
    }

    deletePost=(post_id)=>{
        deletePost(post_id)
            .then((response)=>{
                const sources = response.data;
                toaster.success('Post Deleted Successfully!')
                this.props.getPostData()
            })
            .catch((error)=>showError(error))
    }

    render() {
        return(
            <React.Fragment>
                <Popover
                    className="login-menu"
                    popoverClassName={"login-menu"}
                    content=
                        {
                            <Menu className="dropdown_menu">
                                {
                                    this.props.editData.author_id===Auth.user().user_id?
                                        <React.Fragment>
                                            <MenuItem
                                                text={"Edit Post"}
                                                onClick={()=>{
                                                    this.setState({
                                                        isOpen:true
                                                    })
                                                }}
                                            />
                                            <MenuItem text={"Delete"}
                                                      onClick={()=>{
                                                          this.deletePost(this.props.post_id)
                                                      }}/>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <MenuItem text={"Report To Admin"}
                                                      onClick={()=>{
                                                          this.reportAbuseAuthor(this.props.post_id)
                                                      }}/>
                                        </React.Fragment>
                                }
                            </Menu>
                        } position={Position.BOTTOM_RIGHT}>
                    <MoreHoriz/>
                </Popover>
                <EditArticle
                    isOpen={this.state.isOpen}
                    editData={this.props.editData}
                    onClose={()=>{
                        this.setState({
                            isOpen:false
                        })
                    }}
                    {...this.props}/>
            </React.Fragment>
        )
    }
}

export default PostMenu;
