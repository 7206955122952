import {combineReducers} from 'redux';
import article from './article';
import author from './author';

const rootReducers = combineReducers({
  article,
  author
});

// export default rootReducers;
export default rootReducers;
