import React, {Component} from 'react';
import CreateArticle from "../../../components/CreateArticle";
import PostList from "../../Home/component/postList";

class PostListing extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <h3 className="post-tab-title">Post</h3>
                    <CreateArticle {...this.props}/>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 mt-2" >
                    <PostList getData={this.getAuthorArticleData} {...this.props} />
                </div>
            </div>
        );
    }
}

export default PostListing;
