// import React, {Component} from 'react';
import {connect} from 'react-redux';

import DraftArticleComponent from '../component/DraftArticleComponent';
import {getArticleData} from "../../../actions/action";

const mapStateToProps = state => {

    return{
        // homeData:state.Home.homeData,
        // suggetionData:state.Home.suggetionData
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getArticleData:()=>{
            getArticleData()
            .then((response)=>{
console.log(response)
            }).catch((error)=>{
                console.log(error.response)
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftArticleComponent);
