import React,{Component, Fragment} from 'react';
import {Popover, Menu, MenuDivider, MenuItem, Position} from "@blueprintjs/core";
import {ErrorRounded} from "@material-ui/icons";
import {Auth} from 'gg-react-utilities';
import {Image} from "./ButtonPublish";
export default class MyMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuisOpen: false,
            isOpen:false,
        }
        this.showMenu = this.showMenu.bind(this);
    }

    handleClose=()=>{
      this.setState({
        isOpen: false
      })
    }

    showMenu(){
          this.setState({
              menuisOpen: !this.state.menuisOpen
          });
      }

    render() {
        return (
            <React.Fragment>
                <div className="login-menu-swatantra">
                    {
                        Auth.isLoggedIn()?
                            <React.Fragment>
                                <Image
                                    image={Auth.user().avatar_url}
                                    className="user-menu-img"
                                    onClick={()=>{
                                        this.props.history.push('/author-detail/'+Auth.user().username+'#ab',{
                                            author_id:Auth.user().user_id
                                        })
                                    }}
                                />
                                <h2
                                    className="user-title"
                                    onClick={()=>{
                                        this.props.history.push('/author-detail/'+Auth.user().username+'#ab',{
                                            author_id:Auth.user().user_id
                                        })
                                    }}
                                >{Auth.user().fname}</h2>
                            </React.Fragment>
                            :
                            ''
                    }

                    <ErrorRounded className="fas fa-exclamation-circle d-inline-block shape"
                                  onClick={()=>{
                                      this.props.history.push('/about-us')
                                  }}
                    />
                </div>
                {/*{*/}
                {/*    Auth.isLoggedIn()?*/}
                {/*        <div className="login-menu-swatantra">*/}
                {/*            <Image*/}
                {/*                image={Auth.user().avatar_url}*/}
                {/*                className="user-menu-img"*/}
                {/*                onClick={()=>{*/}
                {/*                    this.props.history.push('/author-detail/'+Auth.user().username+'#ab',{*/}
                {/*                        author_id:Auth.user().user_id*/}
                {/*                    })*/}
                {/*                }}*/}
                {/*            />*/}
                {/*            <h2*/}
                {/*                className="user-title"*/}
                {/*                onClick={()=>{*/}
                {/*                    this.props.history.push('/author-detail/'+Auth.user().username+'#ab',{*/}
                {/*                        author_id:Auth.user().user_id*/}
                {/*                    })*/}
                {/*                }}*/}
                {/*            >{Auth.user().fname}</h2>*/}
                {/*            <ErrorRounded className="fas fa-exclamation-circle d-inline-block shape"*/}
                {/*                          onClick={()=>{*/}
                {/*                              this.props.history.push('/about-us')*/}
                {/*                          }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        <span/>*/}
                {/*}*/}
            </React.Fragment>
        )
        return(
            <Popover
                className="login-menu-swatantra"
                popoverClassName={"login-menu-swatantra"}
                onClose={()=>{
                    this.setState({
                        menuisOpen:false
                    })
                }}
                content=
                    {
                        <Menu className="dropdown_menu">
                            <MenuItem text="My Profile"
                                      onClick={()=>{
                                          this.props.history.push('/author-detail/'+Auth.user().username+'#ab',{
                                              author_id:Auth.user().user_id
                                          })
                                      }}/>
                            <MenuItem text="About Swatantra"
                                      onClick={()=>{
                                          this.props.history.push('/about-us')
                                      }}/>

                            <MenuDivider/>
                        </Menu>
                    } position={Position.BOTTOM_RIGHT}>
                {
                    Auth.isLoggedIn()?
                        <React.Fragment>
                            <Image
                                image={Auth.user().avatar_url}
                                className="user-menu-img"
                            />
                            <h2 className="user-title">{Auth.user().fname}</h2>
                            <ErrorRounded className="fas fa-exclamation-circle d-inline-block shape"/>
                        </React.Fragment>
                    :
                    <span/>
                }


            </Popover>

        )
    }
}
