import React, {Component} from 'react';
import AuthorDetailContainer from "./container/AuthorDetailContainer";

class AuthorDetail extends Component {
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render() {
        return (
            <AuthorDetailContainer {...this.props} />
        );
    }
}

export default AuthorDetail;
