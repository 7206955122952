import {connect} from 'react-redux';

import ArticleComponent from '../components/ArticleComponent';
import {getArticleData,categoryWisePost,getTagList} from "../actions/action";
import {article,posts} from "../actions";
import {toaster} from "../components/toast";
import {showError} from "../actions/Error";

const mapStateToProps = state => {
    return{
        article:state.article.article
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getArticleData:()=>{
            getTagList()
                .then((response)=>{
                    dispatch(article(response.data))
                }).catch((error)=>{
                    showError(error)
                })
        },
        categoryWisePost:(cat_id)=>{
            return  categoryWisePost(cat_id)
                .then((response)=>{
                    dispatch(posts(response.data))
                    return Promise.resolve(true)
                }).catch((error)=>{
                    showError(error);
                    return Promise.reject(false)
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleComponent);
