import React, {Component} from 'react';
import moment from "moment";
import EmptyComponent from "../../../components/EmptyComponent";
import renderHTML from 'react-render-html';
import {
    ButtonQuickSav,
    ButtonLike,
    ButtonTwitter,
    ButtonVisibility,
    Image
} from "../../../components/ButtonPublish";
import _ from 'lodash';
import LoadingWithOutTopBarComponent from "../../../components/LoadingWithOutTopBarComponent";
import PostMenu from "./PostMenu";
import {Button as ButtonLoader,Menu, MenuItem} from "@blueprintjs/core";
import ReadMoreContent from "../../../components/ReadMoreContent";

export default class PostList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({
            dataSource: null
        },()=>{
            this.setData(this.props.posts)
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setData(nextProps.posts)
    }

    setData = (data) => {
        let dataSource = [];
        if (data) {
            data.map((value, index) => {
                let Author_Image = value.Author_Image ? value.Author_Image : null
                let Tag = value.Tag ? value.Tag.split(',') : [];
                let tag_id = value.tag_id ? value.tag_id.split(',') : [];
                dataSource.push({
                    Author_Image: value.Author_Image,
                    Tag: _.uniq(Tag),
                    tag_id: _.uniq(tag_id),
                    Author: value.Author,
                    author_id: value.author_id,
                    created_At: value.created_At,
                    post_id: value.post_id,
                    post_like_count: value.post_like_count,
                    post_view_count: value.post_view_count,
                    Image: value.Image,
                    post_title: value.post_title,
                    post_content: value.post_content,
                    url: value.url?value.url:null,
                    is_like: value.is_like,
                    is_other_like: value.is_other_like,
                    is_quick_save: value.is_quick_save
                });
            })
            this.setState({
                dataSource: dataSource
            })
        }
    }

    render() {

        if (this.state.dataSource===null){

            return (
                <LoadingWithOutTopBarComponent {...this.props}/>
            )
        }

        if (this.state.dataSource.length <= 0) {
            return (
                <EmptyComponent {...this.props}/>
            )
        }
        return (
            <div className="mt-lg-4">
                <div className={"row align-items-center"}>
                    {
                        this.state.dataSource.map((value, index) => {

                            return (
                                <div className="col-md-12 col-sm-12 col-xs-12 mb-3" key={index}>
                                    <div className="card clearfix">
                                        <div className="card-header ml-2 mr-2 p-15">
                                            <div className="card-header-menu float-left ">
                                                <div className="uaser d-flex">
                                                    <img src={value.Author_Image}
                                                         className="user-menu-img"
                                                    />
                                                    <div className="">
                                                        <a className="card-primary-color publisher_name"
                                                           onClick={() => {
                                                               this.props.history.push('/author-detail/' + value.Author, {
                                                                   author_id: value.author_id
                                                               })
                                                           }}>
                                                            {value.Author}
                                                        </a>
                                                        <small>{moment(value.created_At).format('Do MMMM YYYY')}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icon float-right">
                                                <PostMenu
                                                    editData={value}
                                                    post_id={value.post_id}
                                                    getPostData={()=>{
                                                        this.props.getData('remove')
                                                    }}
                                                    {...this.props}/>
                                            </div>
                                        </div>
                                        <div className="body-menu card-body text-left ml-2 mr-2 p-15 clearfix">
                                            <h2 className="post-title mb-3">{value.post_title}</h2>
                                            <ReadMoreContent
                                                readMore={this.props.readMore}
                                                post_content={value.post_content}
                                                url={value.url}
                                                Author={value.Author}
                                                post_id={value.post_id}
                                            />
                                            {
                                                value.Tag.map((Tagvalue, TagIndex) => {
                                                    return (
                                                        <a key={TagIndex} type="button"
                                                           className="artical-button artical-button-list  mb-3 mr-2"
                                                           onClick={() => {
                                                               this.props.history.push('/tag/' + Tagvalue, {
                                                                   tag_name: Tagvalue,
                                                               });
                                                           }}
                                                        >{Tagvalue}</a>
                                                    )
                                                })
                                            }

                                            <div className="mb-3 post-img-bder-swa">
                                                <img src={value.Image}
                                                     className="img-fluid post-image"
                                                />
                                            </div>

                                                <ButtonVisibility
                                                    is_like={value.is_like}
                                                    post_view_count={value.post_view_count}
                                                    is_other_like={value.is_other_like}
                                                />
                                        </div>
                                        <div className="body-menu clearfix">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-4 col-4 text-center pt-3 p-xs-0">
                                                    <ButtonLike
                                                        className="i-support"
                                                        loading={false}
                                                        post_like_count={value.post_like_count}
                                                        post_id={value.post_id}
                                                        is_like={value.is_like}
                                                        getPostData={()=>{
                                                            this.props.getData()
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-4 text-center pt-3 p-xs-0">
                                                    <ButtonQuickSav
                                                        className="bookmarked"
                                                        loading={false}
                                                        is_quick_save={value.is_quick_save}
                                                        post_id={value.post_id}
                                                        getPostData={()=>{
                                                            this.props.getData()
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-4 text-center pt-3 p-xs-0">
                                                    <ButtonTwitter
                                                        share_url={'/article/' + value.Author+'/'+value.post_id}
                                                        post_title={value.post_title}
                                                        postSlug={value.postSlug}
                                                        Tag={value.Tag}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
