import {connect} from 'react-redux';

import MyProfileComponent from '../component/MyProfileComponent';
import {
    getAuthorArticleData,
    updateProfile,
    showAuthorById
} from "../../../actions/action";
import {posts,showAuthorDetail} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        profile:state.author.author
    }
}

const mapDispatchToProps = dispatch => {

    return {
        showAuthorById:(author_id)=>{
            dispatch(showAuthorDetail([]))
            showAuthorById(author_id)
                .then((response)=>{
                    dispatch(showAuthorDetail(response.data.author_details))
                    dispatch(posts(response.data.author_post))
                }).catch((error)=>{
                    let sources = error.response;
                    dispatch(showAuthorDetail([]))
                    showError(error)
                })
        },
        updateProfile:(data)=>{
            return  updateProfile(data);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileComponent);
