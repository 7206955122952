import React, { Component } from 'react';

import ArticleContainer from "../../../container/ArticleContainer";
import TopBar from "../../../components/topBar";
import {showError} from "../../../actions/Error";
import EmptyComponent from "../../../components/EmptyComponent";
import LoadingComponent from "../../../components/LoadingComponent";
import {ButtonTagFollow} from '../../../components/ButtonPublish'
import Container from "../../../components/Container";

export default class AllTagsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getMyFollowTag()
  }

  changeFollowStatus=(tag_id)=>{
    let data={tag_id:tag_id};
    this.props.tagfollow(data)
        .then((response)=>{
          const sources = response.data;
          this.props.getMyFollowTag()
        }).catch((error)=>{
          showError(error)
        });
  }

  render() {

    if (this.props.tags===null){
      return (
          <LoadingComponent {...this.props}/>
      )
    }
    if (this.props.tags.length<=0){
      return (
          <EmptyComponent
              message={"User Tag not Available"}
              {...this.props}
          />
      )
    }
    return (
        <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <h3 className="post-tab-title">My Follow Tags</h3>
            </div>
            {
                this.props.tags.map((value, index)=>{
                    return(
                        <div className="col-md-6 col-sm-6 col-xs-12 col-12">
                            <div className="border tag-details mb-4 p-15 clearfix">
                                <h3 className="float-left font-16">{value.tag_name}</h3>
                                <p className="float-right m-0">

                                    <ButtonTagFollow
                                        getMyFollowTag={()=>{ this.props.getMyFollowTag() }}
                                        type="button"
                                        loading={false}
                                        is_selected={value.is_selected}
                                        tag_id={value.tag_id}
                                    />
                                </p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
  }
}
