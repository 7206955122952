import React, {Component} from 'react';
import {showError} from "../actions/Error";
import {getArticleDetailById, getTagList} from "../actions/action";
import Select from "react-select";
import Dropzone from "../components/DropZone";
import ReactTextFormatComponent from '../components/ReactTextFormat';
import _ from "lodash";

class ArticleCreateComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            post_id:0,
            articleDetail: {},
            postStatus: {label:'Enable',value:'enable'},
            post_title: '',
            post_content: '',
            loading: false,
            selectedTagOption:[],
            tagOption:[]
        }
    }

    componentDidMount() {
        this.getTagList()
    }

    getArticleDetailById=(post_id)=>{
        getArticleDetailById(post_id)
        .then((response)=>{
            const sources = response.data;
            let articleDetail = sources;
            if (articleDetail.length>0){
                articleDetail=articleDetail[0];
                this.setState({
                    imagePreviewUrl:articleDetail.Image,
                    post_content:articleDetail.post_content,
                    url:articleDetail.url,
                    post_title:articleDetail.post_title,
                    Tag:articleDetail.tag_id.split(','),
                    postStatus: {label:articleDetail.post_status,value:articleDetail.post_status},
                    articleDetail:articleDetail
                },()=>{
                    this.selectTag()
                })
            }
        }).catch((error)=>{
            showError(error)
        })
    }

    selectTag=()=>{
        let selectedIndex = [];
        this.state.Tag.filter(a=> {
            let index = this.state.tagOption.find(s=>s.value==a);
            if (index){
                selectedIndex.push({label:index.label,value:index.value})
            }
        })
        this.setState({
            selectedTagOption:_.uniqBy(selectedIndex,'value')
        })
    }

    getTagList=()=>{

        getTagList()
            .then((response)=>{
                const sources = response.data;
                let tagOption = sources.map(a=>{ return{label:a.tag_name,value:a.tag_id} })
                this.setState({
                    tagOption:tagOption
                },()=>{
                    if (this.props.editData){
                        this.getArticleDetailById(this.props.editData.post_id)
                    }
                })
            })
            .catch((error)=>{
                showError(error)
            })
    }

    handleChange = selectedOption => {
        this.setState({ selectedTagOption:selectedOption });
        this.props.onHandleChange('selectedTagOption',selectedOption)
    }

    handlePostChange = selectedOption => {
        this.setState({ postStatus:selectedOption });
        this.props.onHandleChange('postStatus',selectedOption)
    }

    handleDateChange = post_content => {
        this.setState({ post_content:post_content });
        this.props.onHandleChange('post_content',post_content)
    }

    onhandleChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
        this.props.onHandleChange([e.target.name],e.target.value)
    }

    handleImageChange=(acceptedFiles)=> {

        let reader = new FileReader();
        if (acceptedFiles.length>0){
            let file = acceptedFiles[0];
            reader.onloadend = () => {
                this.setState({
                    image: file,
                    imagePreviewUrl: reader.result
                },()=>{
                    this.props.onHandleChange('imagePreviewUrl',reader.result)
                });
            }

            reader.readAsDataURL(file)
        }else {
            this.setState({
                image: '',
                imagePreviewUrl: ''
            },()=>{
                this.props.onHandleChange('imagePreviewUrl','')
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <input type="text"
                       className="artical-title"
                       placeholder="Article Title"
                       name="post_title"
                       id="txt_title"
                       value={this.state.post_title}
                       onChange={this.onhandleChange}
                       required=""
                />
                <ReactTextFormatComponent
                    value={this.state.post_content}
                    onChange={(value)=>{
                        this.setState({
                            post_content:value
                        },()=>{
                            this.props.onHandleChange('post_content',value)
                        })
                    }}
                />
                <ReactTextFormatComponent
                    type={"textbox"}
                    value={this.state.url}
                    onChange={(value)=>{
                        this.setState({
                            url:value
                        },()=>{
                            this.props.onHandleChange('url',value)
                        })
                    }}
                />
                <div className="mt-5 mb-5">
                    <Dropzone
                        imagePreviewUrl={this.state.imagePreviewUrl}
                        isUploadHidden={true}
                        onChange={this.handleImageChange}
                    />
                </div>
                <div className="row mt-2">
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-5">
                        <Select
                            name={"tag_list[]"}
                            isMulti
                            value={this.state.selectedTagOption}
                            onChange={this.handleChange}
                            options={this.state.tagOption}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-5">
                        <Select
                            name={"post_status"}
                            value={this.state.postStatus}
                            onChange={this.handlePostChange}
                            options={[
                                {label:'Enable',value:'enable'},
                                {label:'Disable',value:'disable'},
                            ]}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ArticleCreateComponent;
