
let initialState = {
    author: []
};
const renderAuthor = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_AUTHOR_DETAIL':
            return {...state, author: action.author}
        default:
            return state
    }
}

export default renderAuthor
