import React, {Component} from 'react';
import CreateArticle from "./CreateArticle";

class ArticleComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            tag_name:"/"
        }
    }

    componentDidMount() {
        if (this.props.location.state){
            this.setState({
                tag_name:this.props.location.state.tag_name
            })
        }
        this.props.getArticleData()
    }

    categoryWisePost=(cat_id)=>{
        this.props.categoryWisePost(cat_id)
            .then(()=>{
                this.props.history.push('/')
            })
    }

    render() {

        // return (
        //     <React.Fragment>
        //         <button type="button" className="bp3 bp3-primary user-model mt-5 mb-5 col-12">
        //             <AvtarImage/>
        //             <span className="mind">Whats in your mind, Vikas?</span>
        //         </button>
        //         <a href={"/"}
        //            className={this.state.tag_name==="/"?"artical-button active mr-2":"artical-button mr-2"}
        //            >All Articles</a>
        //         {
        //             this.props.article.map((value, index)=>{
        //                 let className="artical-button mr-2";
        //                 if (this.state.tag_name===value.tag_name){
        //                     className="artical-button active mr-2";
        //                 }
        //                 return(
        //                     <a className={className}
        //                        key={index}
        //                        title={value.tag_name}
        //                        onClick={()=> {
        //                            this.props.history.push('/tag/'+value.tag_name,{
        //                                tag_name:value.tag_name,
        //                            });
        //                        }}
        //                     >{value.tag_name}</a>
        //                 )
        //             })
        //         }
        //
        //     </React.Fragment>
        // )

        return (
            <React.Fragment>
                {
                    this.props.article.length>0?
                        <a className={this.state.tag_name==='/'?"artical-button mr-2 mt-2 active":"artical-button mt-2 mr-2"}
                           onClick={()=> {
                               this.props.history.push('/');
                           }}>All Articles</a>
                    :
                    ''
                }

                {
                    this.props.article.map((value, index)=>{
                        return(
                            <a className={this.state.tag_name===value.tag_name?"artical-button mt-2 mr-2 active":"artical-button mt-2 mr-2"}
                               title={value.tag_name}
                               key={index}
                               onClick={()=> {
                                   this.props.history.push('/tag/'+value.tag_name,{
                                       tag_name:value.tag_name,
                                   });
                               }}
                            >{value.tag_name}</a>
                        )
                    })
                }
            </React.Fragment>
        );
    }
}

export default ArticleComponent;
