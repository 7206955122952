import React,{Component} from 'react';
import CKEditor from 'ckeditor4-react';

class ReactTextFormat extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:''
        }
    }
    componentDidMount() {
        this.setState({
            data:this.props.value
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data:nextProps.value
        })
    }

    handleChange=( changeEvent )=> {
        this.setState( {
            data: changeEvent.target.value
        } ,()=>{
            this.props.onChange(this.state.data)
        } );
    }

    onEditorChange=( evt )=> {
        this.setState( {
            data: evt.editor.getData()
        } ,()=>{
            this.props.onChange(this.state.data)
        } );
    }

    render() {

        // return (
        //     <CKEditor
        //
        //         data={this.state.data}
        //         onChange={this.onEditorChange} />
        // )

        if (this.props.type==="textbox"){
            return (
                <input
                    type="url"
                    name="url"
                    placeholder="Video Url"
                    className="artical-title mt-2"
                    defaultValue={this.state.data}
                    value={this.state.data}
                    onChange={this.handleChange} />
            )
        }

        return (
            <textarea
                name="post_content"
                rows={10}
                placeholder="Write Article"
                className="w-100 mt-2"
                defaultValue={this.state.data}
                value={this.state.data}
                onChange={this.handleChange} />
        )
    }
}

export default ReactTextFormat;
