import React, {Component} from 'react';
import AboutContainer from "./container/AboutContainer";

class About extends Component {
    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <AboutContainer {...this.props}/>
        );
    }
}

export default About;
