// import React, {Component} from 'react';
import {connect} from 'react-redux';

import HomeComponent from '../component/HomeComponent';
import {articlePostLike, getPostData, quickSave,getusertag} from "../../../actions/action";
import {posts} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        posts:state.article.posts
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getPostData:(type)=>{
            if (type==="remove"){
                dispatch(posts(null))
            }

            getPostData()
                .then((response)=>{
                    const sources = response.data;
                    // console.log(sources,"sources")
                    // if (sources===0){
                    //     window.location.href='/tag-following';
                    // }
                    return  response;
                })
                .then((response)=>{
                    dispatch(posts(response.data))
                }).catch((error)=>{
                    let sources = error.response;
                    dispatch(posts([]))
                })

        },
        articlePostLike:(post_id)=>{
            return  articlePostLike(post_id);
        },
        quickSave:(post_id)=>{
            return  quickSave(post_id);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
