import React, { Component } from 'react';
import PostList from "../../Home/component/postList";
import LoadingWithOutTopBarComponent from "../../../components/LoadingWithOutTopBarComponent";
import BOOKMARKERROR from "../../../images/bookmark.svg";

export default class QuickSaveArticleComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getQuickArticleList('remove')
  }

  render() {
      if (this.props.quicksaveArticle===null){
          return (
              <LoadingWithOutTopBarComponent {...this.props}/>
          );
      }
      if (this.props.quicksaveArticle.length<=0){
          return (
              <div className={"container"}>
                  <center>
                      <img src={BOOKMARKERROR}
                           alt="error" className="errorImg img-fluid"/>
                      <h2 className="mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3">You didn't mark any bookmark yet.</h2>
                  </center>
              </div>
          );
      }
        return (
          <div>
              <h3 className="post-tab-title">My Bookmarks</h3>
              <PostList
                {...this.props}
                getData={this.props.getQuickArticleList}
                posts={this.props.quicksaveArticle}
                />
          </div>
        );
  }
}
