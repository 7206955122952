import React, {useState} from "react";
import {
    Button as ButtonLoader,
    AnchorButton,
    Menu,
    Position,
    Popover
} from '@blueprintjs/core';
import {
    articlePostLike,
    quickSave,
    userAuthorFollow,
    addcomment, tagfollow
} from "../actions/action";
import {toaster} from "./toast";
import {showError} from "../actions/Error";
import {
    BookmarkBorder,
    Bookmark,
    ThumbUp,
    Share,
    Report,
    Comment
} from "@material-ui/icons";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramIcon,
    TelegramShareButton,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";
import {Auth} from "gg-react-utilities";
import EditArticle from "./EditArticle";

export const Button = (props)=>{

    const [loading,setLoading] = React.useState(props.loading);

    const addcomments = (post_id) =>{
        if (props.comment_content){
            addcomment(post_id,props.comment_content)
                .then((response)=>{
                    const source = response.data;
                    toaster.success('Comment Add Successfully!');
                    setLoading(false)
                    props.getPostArticleData()
                }).catch((errors)=>{
                setLoading(false)
                    toaster.error('Something Went Wrong!');
                });
        }else {
            setLoading(false)
            toaster.error('Please Enter Comment Text')
        }
    }

    return(
        <ButtonLoader
            {...props}
            type="button"
            title={"Add Comment"}
            loading={loading}
            onClick={()=>{
                setLoading(true);
                addcomments(props.post_id)
            }}
        >
            {props.children}
        </ButtonLoader>
    )
}

export const ButtonFollow = (props)=>{
    const [loading,setLoading] = React.useState(props.loading);
    const adUserAuthorFollow = (author_id) =>{
        setLoading(true)
        userAuthorFollow(author_id)
            .then((response)=>{
                const source = response.data;
                setLoading(false)
                props.getPostArticleData()
            }).catch((errors)=>{
                setLoading(false)
                showError(errors)
            });
    }
    let className = props.user_following ? "bp3-button bp3-intent-danger " : "bp3-button bp3-intent-primary ";
    className+=props.className
    console.log(className)
    return(
        <ButtonLoader
            {...props}
            type="button"
            title={props.user_following ? "UNFOLLOW" : "FOLLOW"}
            loading={loading}
            intent="primary"
            className={className}
            onClick={()=>{
                setLoading(true);
                adUserAuthorFollow(props.author_id)
            }}
        >
            {props.user_following ? "UNFOLLOW" : "FOLLOW"}
        </ButtonLoader>
    )
}

export const ButtonTagFollow = (props)=>{

    const [loading,setLoading] = React.useState(props.loading);

    const changeFollowStatus = (tag_id) =>{
        let data={tag_id:tag_id};
        tagfollow(data)
        .then((response)=>{
            const sources = response.data;
            setLoading(false)
            props.getMyFollowTag()
        }).catch((error)=>{
            showError(error)
            setLoading(false)
        });
    }

    return(
        <ButtonLoader
            {...props}
            type="button"
            title={props.is_selected===1 ? "Unfollow" : "Follow"}
            loading={loading}
            className={props.is_selected===1 ? "bp3-button bp3-intent-danger mt-3" : "bp3-button bp3-intent-primary mt-3"}
            onClick={()=>{
                setLoading(true);
                changeFollowStatus(props.tag_id)
            }}
        >
            {props.is_selected===1 ? "Unfollow" : "Follow"}
        </ButtonLoader>
    )
}

export const ButtonQuickSav = (props)=>{

    const [loading,setLoading]=React.useState(false);

    const onClickQuickSave=(post_id)=>{
        setLoading(true)
        quickSave(post_id)
            .then((response)=>{
                const sources = response.data;
                if (sources.success){
                    toaster.success("Post has been added to Quick Saved list.")
                }else {
                    toaster.success("Post has been removed from Quick Saved list.")
                }
                setLoading(false)
                props.getPostData()
            }).catch((error)=>{
                setLoading(false)
                showError(error)
            });
    }
    let color="#727272";
    if (props.is_quick_save===1){
        color="#20BF55";
    }
    return (
        <ButtonLoader
            href={"#bookmark_"+1}
            title={"Post Quick Saved"}
            className={props.is_quick_save===1?"bookmarked article-button":"bookmark article-button"}
            loading={loading}
            style={{
                color:color
            }}
            onClick={()=>{
                onClickQuickSave(props.post_id)
            }}>
            <span>
            {props.is_quick_save?<Bookmark fontSize="small" className="mr-2"/>:<BookmarkBorder fontSize="small" className="mr-2"/>}
            </span>
            {props.is_quick_save?'Bookmarked':'Bookmark'}
        </ButtonLoader>
    )
}

export const ButtonLike = (props)=>{
    const [loading,setLoading]=React.useState(false);
    const addLike=(post_id)=>{
        setLoading(true)
        articlePostLike(post_id)
            .then((response)=>{
                const sources = response.data;
                setLoading(false)
                props.getPostData()
            }).catch((error)=>{
                showError(error)
                setLoading(false)
            });
    }
    let color="#727272";
    if (props.is_like===1){
        color="#236AEE";
    }
    return (
        <ButtonLoader
            href={"#like_"+1}
            title={'I Support'}
            loading={loading}
            className={props.is_like===1?"i-supported article-button":"i-support article-button"}
            style={{
                color:color
            }}
           onClick={()=>{
               addLike(props.post_id)
           }}>
            <span>
                <ThumbUp fontSize="small" className="mr-2"/>
            </span>
            {props.is_like===1?"I Supported":"I Support"}
        </ButtonLoader>
    )
}

export const ButtonVisibility = (props)=>{
    const [loading,setLoading] = React.useState(props.loading);

    return (
        <React.Fragment>
            <span className="float-left review-suppoort pb-3 ml-2">{props.is_like===1?(props.is_other_like>0?"You and "+props.is_other_like+" Others Supported":'You Supported'):(props.is_other_like>0?props.is_other_like+"  Supported":'')} </span>
            <span className="views float-right pb-3 mr-2">{props.post_view_count} Views</span>
        </React.Fragment>
    )
}

export const ButtonReport = (props)=>{
    const [loading,setLoading] = React.useState(props.loading);
    const [isOpen,setIsOpen] = React.useState(false);
    const reportAbuseAuthors=()=>{
        setIsOpen(true)
    }
    return(
        <React.Fragment>
            <AnchorButton
                {...props}
                type="button"
                title={"Report"}
                loading={loading}
                onClick={()=>{
                    reportAbuseAuthors()
                }}
            >
                <Report/>
            </AnchorButton>
        </React.Fragment>
    )
}

export const ButtonEdit = (props)=>{
    const [loading,setLoading] = React.useState(props.loading);

    return(
        <EditArticle/>
    )
}

export const ButtonTwitter = (props)=>{

    const [isOpen,setIsOpen]=useState(false);
    let share_url=process.env.REACT_APP_SHARE_URL+props.share_url;
    return (
        <Popover
            isOpen={isOpen}
            className="login-menu"
            popoverClassName={"login-menu"}
            content=
                {
                    <Menu className="dropdown_menu share-button-swatantra" >
                        <FacebookShareButton
                            url={share_url}
                            quote={props.Tag+" - World is yours to explore"}
                            hashtag={props.Tag}
                            className={"m-3"}>
                            <FacebookIcon size={36} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                            url={share_url}
                            quote={props.Tag+" - World is yours to explore"}
                            hashtag={props.Tag}
                            className={"m-3"}>
                            <WhatsappIcon size={36} />
                        </WhatsappShareButton>
                        <TwitterShareButton
                            url={share_url}
                            quote={props.Tag+" - World is yours to explore"}
                            hashtag={props.Tag}
                            className={"m-3"}>
                            <TwitterIcon size={36} />
                        </TwitterShareButton>
                        <TelegramShareButton
                            url={share_url}
                            quote={props.Tag+" - World is yours to explore"}
                            hashtag={props.Tag}
                            className={"m-3"}>
                            <TelegramIcon size={36} />
                        </TelegramShareButton>
                        <LinkedinShareButton
                            url={share_url}
                            quote={props.Tag+" - World is yours to explore"}
                            hashtag={props.Tag}
                            className={"m-3"}>
                            <LinkedinIcon size={36} />
                        </LinkedinShareButton>
                    </Menu>
                } position={Position.BOTTOM_RIGHT}>
            <ButtonLoader
                target="_blank"
                title="share twitter"
                onClick={()=>{
                    let appType = window.swatantra.getDeviceName();
                    if(appType !== 'WEB'){
                        window.swatantra.showSharingOption( share_url );
                    }else{
                        setIsOpen(!isOpen)
                    }
                }}
                className="share article-button">
                                <span className="mr-2">
                                <Share fontSize="small" />
                                </span>
                Share
            </ButtonLoader>
        </Popover>
    )
}

export const ButtonCommentCount = (props)=>{

    const [loading,setLoading] = React.useState(props.loading);
    const [post_comment_count,setPostCommentCount] = React.useState(props.post_comment_count);

    return(
        <AnchorButton
            {...props}
            type="button"
            title="Comment Count"
            loading={loading}
        >
            <Comment/>
            <span className="card-color-black">{post_comment_count}</span>
        </AnchorButton>
    )
}

export const AvtarImage = () =>{

    return (
        <Image
            image={Auth.user().avatar_url}
            className="mask d-inline-block mr-5"
        />
    )
}

export const Image=(props)=>{

    const [image,setImage]=React.useState(props.image?props.image:'https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/header-icon/user.svg');

    return(
        <img src={image}
             onError={()=>{
                 setImage('https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/header-icon/user.svg')
             }}
             className="user-menu-img"
             {...props}
        />
    )
}
