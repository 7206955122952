import React, {Component} from 'react';
import {NoLogin,Auth} from 'gg-react-utilities';
import TopBar from "./topBar";
import LoaderImg from "../images/100x100-Loader.gif";

class LoadingComponent extends Component {
    render() {
        return (
            <div className="container">
                <TopBar {...this.props} title={"Loading..."}/>
                {
                    Auth.isLoggedIn()?
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={LoaderImg} className="img-fluid mt-5" alt="" />
                            </div>
                        </div>
                        :
                        <NoLogin/>
                }
            </div>
        );
    }
}

export default LoadingComponent;
