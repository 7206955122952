import React from 'react';
import {Header, Footer, Auth} from 'gg-react-utilities';

export default class App extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isHeader:'Web',

        }
    }

     componentDidMount(){

        setTimeout(async ()=>{
            let isHeader = 'Web';
            if (typeof getDeviceName === 'function' && window['getDeviceName'] !== undefined){
               await Auth.update();
                isHeader = window.getDeviceName();
            }else if(typeof Android !== "undefined" && window['Android'] !== null) {
                await Auth.update();
                isHeader = window.Android.getDeviceName();
            }

            this.setState({
                isHeader:isHeader
            })
        },50)

    }

    render(){

        let headerType='dark';
        // if (window.location.pathname === '/'){
        //     headerType='dark';
        // }

        return (
            <React.Fragment>
                {
                    this.state.isHeader===undefined || this.state.isHeader==='Web'?
                        <Header headerType={headerType} />
                        :
                        ''
                }

                {this.props.children}
                {
                    this.state.isHeader===undefined || this.state.isHeader==='Web'?
                        <Footer/>
                        :
                        ''
                }

            </React.Fragment>
        )

    }
}
