// import React, {Component} from 'react';
import {connect} from 'react-redux';

import TagArticleViewComponent from '../component/TagArticleViewComponent';
import {articlePostLike, getPostData, quickSave, getusertag, showTagArticleById} from "../../../actions/action";
import {posts, setArticleDetail, showAuthorDetail} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        posts:state.article.posts
    }
}

const mapDispatchToProps = dispatch => {

    return {
        showAuthorById:(tag_name,type='remove')=>{
            showTagArticleById(tag_name)
                .then((response)=>{
                    if (type==="remove"){
                        dispatch(posts(null))
                    }
                    dispatch(posts(response.data))
                }).catch((error)=>{
                    dispatch(posts([]))
                    showError(error)
                })
        },
        articlePostLike:(post_id)=>{
            return  articlePostLike(post_id);
        },
        quickSave:(post_id)=>{
            return  quickSave(post_id);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagArticleViewComponent);
