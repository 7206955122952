import {connect} from 'react-redux';

import ArticleDetailsComponent from '../component/ArticleDetailsComponent';
import {articlePostLike,
    getArticleDetail,
    quickSave,
    userAuthorFollow,
    getArticleComment,addcomment} from "../../../actions/action";
import {setArticleDetail,setArticleComment} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {

    return{
        details:state.article.details,
        comments:state.article.comments
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getArticleDetail:(post_id)=>{
            getArticleDetail(post_id)
            .then((response)=>{
                const sources = response.data;
                dispatch(setArticleDetail(sources));
            }).catch((error)=>{
                showError(error)
                dispatch(setArticleDetail([]));
            })
        },
        userAuthorFollow:(author_id)=>{
            return  userAuthorFollow(author_id);
        },
        getArticleComment:(post_id)=>{
            getArticleComment(post_id)
            .then((response)=>{
                const sources = response.data;
                dispatch(setArticleComment(sources));
            }).catch((error)=>{
                showError(error)
                dispatch(setArticleComment([]));
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailsComponent);
