import {ssoInstance,Auth as Author} from 'gg-react-utilities';
import CryptoJS from 'crypto-js';
import Config from '../Config';
import {showError} from "../actions/Error";

export default class Auth{

    static isLoggedIn(){

        const user = localStorage.getItem('user');

        if(!user){
            ssoInstance.get(process.env.REACT_APP_ACCOUNT_API_URL+'api/v1.0.2/user/profile\n')
                .then( (response)=> {
                    // Encrypt
                    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), 'globalgarner-account').toString();
                    localStorage.setItem('user',ciphertext);

                    return response.data;
                }).catch((error)=>{
                  showError(error)
                })
            return false
        }

        return true;

    }

    static user= ()=>{

        const user = localStorage.getItem('user');

        if(user === null || user === undefined){
            return {};
        }
        // Decrypt
        var bytes = CryptoJS.AES.decrypt(user, 'globalgarner-account');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    //
    // static update(){
    //
    //     const state = store.getState();
    //
    //     autoInstance.get(Config.UPOS_API_URL + '/agents/profile')
    //         .then(function (response) {
    //
    //             // store.dispatch(setAgent(response.data));
    //             return true;
    //
    //         })
    //
    //     return state.agent.Agent.agent;
    //
    // }
    //
    // static login() {
    //
    //     window.location.href = Config.LOGIN_URL;
    //
    // }
    //
    // static logout() {
    //     window.location.href = Config.LOGOUT_URL;
    // }

}
