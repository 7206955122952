import React, {Component} from 'react';
import ArticleContainer from "../container/ArticleContainer";
import TopBar from "./topBar";

class Container extends Component {
    render() {

        return (
            <React.Fragment>
                <div className="container">
                    <TopBar title={"View Article"} {...this.props} />
                    <div className="mt-5 mb-5">
                        <div className="">
                            <div className="row">
                                <ArticleContainer {...this.props}/>
                                <div className="col-md-12 col-sm-12 col-xs-12 mt-2" >
                                    {this.props.children}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        );
    }
}

export default Container;
