import React,{ Component } from 'react';
import DraftArticleContainer from './container/DraftArticleContainer';

export default class DraftArticle extends Component{
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render(){

        return(
            <React.Fragment>
                <DraftArticleContainer {...this.props} />
            </React.Fragment>

        )
    }
}
