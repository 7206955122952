import React, {Component} from 'react';
import {Button} from '@blueprintjs/core';
import {Auth} from 'gg-react-utilities';
import {
    Create,
    Maximize,
} from "@material-ui/icons";
import {showError} from "../../../actions/Error";
import {toaster} from "../../../components/toast";
import LoadingComponent from "../../../components/LoadingComponent";
import Modal from "../../../components/Modal";
import {Image} from "../../../components/ButtonPublish";
import Dropzone from "../../../components/DropZone";
import LoadingWithOutTopBarComponent from "../../../components/LoadingWithOutTopBarComponent";

class MyProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            author: null,
            author_id: null,
            loader: false,
            editProfile: false
        }
    }

    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile.length>0){
            let profile = nextProps.profile[0]
            this.setState({
                author:profile,
                imagePreviewUrl:profile.profile_pic,
                name:profile.name,
                display_name:profile.display_name,
                email:profile.email,
                city:profile.city,
                state:profile.state,
                country:profile.country,
                About_Author:profile.About_Author,
            })
        }
    }

    getData=()=>{
        try {
            let author_id = this.props.location.state.author_id;
            this.setState({
                author_id: author_id
            }, () => {
                this.getAuthorArticleData()
            })
        } catch (e) {
            if (Auth.isLoggedIn()) {
                let author_id = Auth.user().user_id;
                this.setState({
                    author_id: author_id
                }, () => {
                    this.getAuthorArticleData()
                })
            } else {
                this.props.history.push('/')
            }
        }
    }

    getAuthorArticleData = () => {
        let author_id = this.state.author_id;
        if (author_id) {
            this.props.showAuthorById(author_id)
        }
    }

    onhandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleImageChange=(acceptedFiles)=> {

        let reader = new FileReader();
        let file = acceptedFiles[0];
        reader.onloadend = () => {
            this.setState({
                image: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    handleSubmit = () => {
        this.setState({
            loader: true
        }, () => {
            let data = {
                // image: this.state.imagePreviewUrl,
                // name: this.state.name,
                // email: this.state.email,
                // display_name: this.state.display_name,
                // city: this.state.city,
                // country: this.state.country,
                // state: this.state.state,
                about_author: this.state.About_Author,
            }
            this.props.updateProfile(data)
                .then((response) => {
                    const sources = response.data;
                    toaster.success('Profile Update Successfully!')
                    this.setState({
                        loader: false,
                        author: null,
                        editProfile: false
                    }, () => {
                        this.getData()
                    })
                }).catch((error) => {
                    this.setState({
                        loader: false,
                    })
                    showError(error)
                });
        })
    }

    render() {
        if (this.state.author === null) {
            return (
                <LoadingWithOutTopBarComponent {...this.props}/>
            );
        }
        return (
            <React.Fragment>
            <h3 className="post-tab-title">About</h3>
                <div className="row">
                    <div className="col-md-10 col-sm-10 col-8 d-inline-flex">
                        {
                            this.state.About_Author ?
                                <p className="font-12 text-justify m-xs-0"
                                   dangerouslySetInnerHTML={{__html: this.state.About_Author}}
                                />
                                :
                                ''
                        }
                        {/*<Image image={this.state.imagePreviewUrl}*/}
                        {/*       className="tab-mask"*/}
                        {/*/>*/}
                        {/*<div className="user-list">*/}
                        {/*    <h2 className="user-name m-xs-0 text-left font-14">{this.state.name}</h2>*/}
                        {/*    <p className="user-title-menu text-left font-12">#{this.state.display_name}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-md-2 col-sm-2 col-4 text-right p-xs-2">
                        {
                            this.state.author.sso_user_id===Auth.user().user_id?
                                <Modal
                                    size="lg"
                                    toggle={
                                        <Button
                                          icon="edit"
                                          intent="none"
                                          className="edit-profile"
                                          text="Edit"
                                          onClick={() => {
                                              this.setState({editProfile: true});
                                          }}
                                        />
                                    }
                                    isOpen={this.state.editProfile}
                                    className="edit-profile-modal"
                                    style={{width: 700}}
                                    onClose={() => {
                                        this.setState({
                                            editProfile: false
                                        })
                                    }}
                                >
                                    <div className="bp3-dialog-header">
                                        <h4 className="bp3-heading edit-profile-title">Edit About Us</h4>
                                        <button aria-label="Close"
                                                onClick={() => {
                                                    this.setState({
                                                        editProfile: false
                                                    })
                                                }}
                                                className="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"/>
                                    </div>
                                    <div className="bp3-dialog-body">
                                        <form>
                                            <div className="row">
                                                {/*<div className="col-sm-6 col-md-6 col-xs-12">*/}
                                                {/*    <input type="text"*/}
                                                {/*           placeholder="Name"*/}
                                                {/*           name="name"*/}
                                                {/*           className="artical-title"*/}
                                                {/*           value={this.state.name}*/}
                                                {/*           onChange={this.onhandleChange}*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-6 col-md-6 col-xs-12">*/}
                                                {/*    <input type="text"*/}
                                                {/*           placeholder="Display Name"*/}
                                                {/*           name="display_name"*/}
                                                {/*           onChange={this.onhandleChange}*/}
                                                {/*           value={this.state.display_name}*/}
                                                {/*           className="artical-title"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-sm-12 col-md-12 col-xs-12">*/}
                                                {/*    <div className="row">*/}
                                                {/*        <div className="col-sm-6 col-md-6 col-xs-12">*/}
                                                {/*            <input type="text"*/}
                                                {/*                   name="email"*/}
                                                {/*                   placeholder="Email Address"*/}
                                                {/*                   onChange={this.onhandleChange}*/}
                                                {/*                   value={this.state.email}*/}
                                                {/*                   className="artical-title"/>*/}
                                                {/*            <input type="text"*/}
                                                {/*                   name="city"*/}
                                                {/*                   placeholder="City"*/}
                                                {/*                   onChange={this.onhandleChange}*/}
                                                {/*                   value={this.state.city}*/}
                                                {/*                   className="artical-title"/>*/}
                                                {/*            <input type="text"*/}
                                                {/*                   name="state"*/}
                                                {/*                   placeholder="State"*/}
                                                {/*                   onChange={this.onhandleChange}*/}
                                                {/*                   value={this.state.state}*/}
                                                {/*                   className="artical-title"/>*/}
                                                {/*            <input type="text"*/}
                                                {/*                   placeholder="Country"*/}
                                                {/*                   name="country"*/}
                                                {/*                   onChange={this.onhandleChange}*/}
                                                {/*                   value={this.state.country}*/}
                                                {/*                   className="artical-title"/>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="col-sm-6 col-md-6 col-xs-12">*/}
                                                {/*            <Dropzone*/}
                                                {/*                onChange={this.handleImageChange}*/}

                                                {/*            />*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="col-sm-12 col-md-12 col-xs-12">
                                                    <textarea placeholder="About Author"
                                                              name="About_Author"
                                                              onChange={this.onhandleChange}
                                                              className="artical-title">{this.state.About_Author}</textarea>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="bp3-dialog-footer">
                                        <div className="bp3-dialog-footer-actions">
                                            <Button type="button"
                                                    className="bp3-button"
                                                    onClick={()=>{
                                                        this.setState({
                                                            editProfile: false
                                                        })
                                                    }}
                                            >Cancel</Button>
                                            <Button type="button"
                                                    loading={this.state.loader}
                                                    className="bp3-button bp3-intent-primary"
                                                    onClick={this.handleSubmit}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                                :
                                ''
                        }
                    </div>
                </div>
                {/*<div className="info">*/}
                {/*    <p className="font-12 m-xs-0">{this.state.email}</p>*/}
                {/*    <p className="font-12 m-xs-0">{this.state.city}</p>*/}

                {/*</div>*/}
            </React.Fragment>
        )
    }
}

export default MyProfileComponent;
