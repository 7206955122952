import React, {Component} from 'react';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import { PageTitle,Auth } from 'gg-react-utilities';
import MyMenu from "./myMenu";
import Swatantra_logo from "../images/Swatantra_logo.svg";

export default class TopBar extends Component{

    render(){

        let titleClass=this.props.hideLogo===true?"swatantra ml-5":"swatantra ml-4";
        if (this.props.titleClass){
            titleClass+=" "+this.props.titleClass;
        }

        return (
            <div className="row align-items-center">
                <div className="col-md-6 col-sm-6 col-xs-12 col-6 mt-3">
                    {
                        window.location.pathname!=='/'?
                            <KeyboardArrowLeftRounded className='icon back-arrow back-font-icon mt-0'
                                                      onClick={()=>{
                                                          this.props.history.goBack()
                                                      }}/>
                            :
                            ''
                    }
                    {
                        this.props.hideLogo===true?
                            ''
                            :
                            <img className="d-inline-block swatantra-logo"
                                 src={Swatantra_logo}
                                 alt="Swatantra"
                                 onClick={()=>{
                                     this.props.history.push('/')
                                 }}
                                 title="Swatantra"/>
                    }

                    <PageTitle title={this.props.title?this.props.title:'Swatantra | Global Garner'} />
                        <h2 className={titleClass}>{this.props.title?this.props.title:"Swatantra"}</h2>
                </div>
                {
                    window.location.pathname==='/' || window.location.pathname.split('/')[1]==="tag"?
                        <div className="col-md-6 col-sm-6 col-xs-12 col-6">
                            <div className="float-right my-menu-swatantra">
                                <MyMenu {...this.props}/>
                            </div>
                        </div>
                        :
                        ''
                }

            </div>
        )
    }

}
