// import React, {Component} from 'react';
import {connect} from 'react-redux';

import QuickSaveArticleComponent from '../component/QuickSaveArticleComponent';
import {getQuickArticleList} from "../../../actions/action";
import {quickSaveArticle} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {

    return{
        quicksaveArticle:state.article.quicksaveArticle
    }
}

const mapDispatchToProps = dispatch => {

    return {
        getQuickArticleList:(type="update")=>{
            if (type==="remove"){
                dispatch(quickSaveArticle(null))
            }
            getQuickArticleList()
                .then((response)=>{
                    dispatch(quickSaveArticle(response.data))
                }).catch((error)=>{
                showError(error)
                dispatch(quickSaveArticle([]))
                // toaster.error(sources.message);
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSaveArticleComponent);
