import { ssoInstance,rawInstance,Auth,GgUtils } from 'gg-react-utilities';
// import Auth from '../utils/Auth';
export const getPostData=async ()=>{
    let userId = 'user_id=0';
    let currentCity = 'currentCity=Ahmedabad';
    if (Auth.isLoggedIn()){
        userId = 'user_id='+Auth.user().user_id;
        currentCity = 'currentCity='+Auth.user().city;
    }
    let url=process.env.REACT_APP_APP_URL + '/article?'+currentCity+'&'+ userId;
    return ssoInstance.get(url);
}

export const categoryWisePost=async (cat_id)=>{
    let userId = 'user_id='+Auth.user().user_id;
    let currentCity = 'currentCity='+Auth.user().city;

    let url=process.env.REACT_APP_APP_URL + '/category/'+cat_id+'/article?'+currentCity+'&'+ userId;
    return ssoInstance.get(url);
}

export const getArticleData=async ()=>{
    let url=process.env.REACT_APP_APP_URL + '/category';
    return ssoInstance.get(url);
}

export const getAuthorArticleData=async (author_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/author/'+author_id+'/article';
    return ssoInstance.get(url);
}

export const showAuthorById=async (author_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/author/'+author_id;
    return ssoInstance.get(url);
}

export const articlePostLike=async (post_id)=>{
    let data = {user_id:Auth.user().user_id};
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id+'/like';
    return ssoInstance.put(url,data);
}

export const quickSave=async (post_id)=>{
    let data = {user_id:Auth.user().user_id};
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id+'/quicksave';
    return ssoInstance.put(url,data);
}

export const showTagArticleById=async (tag_name)=>{
    let currentCity = '?currentCity='+Auth.user().city;
    let url=process.env.REACT_APP_APP_URL + '/tag/'+ tag_name +'/article'+currentCity;
    return ssoInstance.get(url);
}

export const getQuickArticleList=async ()=>{
    let userId = 'user_id='+Auth.user().user_id;
    let currentCity = 'currentCity='+Auth.user().city;

    let url=process.env.REACT_APP_APP_URL + '/user/'+userId+'/quicksave';
    return ssoInstance.get(url);
}

export const getArticleDetail=async (post_id)=>{
    let data = {user_id:Auth.user().user_id};
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id;
    return ssoInstance.get(url);
}

export const userAuthorFollow=async (author_id)=>{
    let data = {user_id:Auth.user().user_id,author_id:author_id};
    let url=process.env.REACT_APP_APP_URL + '/user/author/follow';
    return ssoInstance.put(url,data);
}

export const getArticleComment=async (post_id)=>{
    // let data = {user_id:Auth.user().user_id,author_id:author_id};
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id+'/comment';
    return ssoInstance.get(url);
}

export const addcomment=async (post_id,comment_content)=>{
    let data = {user_id:Auth.user().user_id,comment_content:comment_content};
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id+'/comment';
    return ssoInstance.post(url,data);
}

export const getArticleDetailById=async (post_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id;
    return ssoInstance.get(url);
}

export const addPost=async (data)=>{
    let url=process.env.REACT_APP_APP_URL + '/article';
    return ssoInstance.post(url,data);
}

export const updatePost=async (post_id,data)=>{
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id;
    return ssoInstance.post(url,data);
}

export const getTagList=async ()=>{
    let url=process.env.REACT_APP_APP_URL + '/tag';
    return rawInstance.get(url);
}

export const getusertag=async ()=>{
    let url=process.env.REACT_APP_APP_URL + '/usertag/'+Auth.user().user_id;
    return ssoInstance.get(url);
}

export const addFollowingTag=async (data)=>{
    let url=process.env.REACT_APP_APP_URL + '/user-web/tag';
    return ssoInstance.post(url,data);
}

export const tagfollow=async (data)=>{
    let url=process.env.REACT_APP_APP_URL + '/usertagfollow';
    return ssoInstance.put(url,data);
}

export const getMyFollowTag=async ()=>{
    let url=process.env.REACT_APP_APP_URL + '/getMyFollowTag/'+Auth.user().user_id;
    return ssoInstance.get(url);
}

export const updateProfile=async (data)=>{
    let url=process.env.REACT_APP_APP_URL + '/update-profile';
    return ssoInstance.post(url,data);
}

export const followingList=async (user_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/following-list/'+user_id;
    return ssoInstance.get(url);
}

export const followerList=async (user_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/follower-list/'+user_id;
    return ssoInstance.get(url);
}

export const getSinglePostQuickSave=async (post_id)=>{
    let user_id = Auth.user().user_id;
    let url=process.env.REACT_APP_APP_URL + '/quicksave/'+post_id+'/'+user_id;
    return ssoInstance.get(url);
}

export const hasLiked=async (post_id)=>{
    let id = Auth.user().user_id;
    let url=process.env.REACT_APP_APP_URL + '/like-count/'+post_id+'/'+id;
    return ssoInstance.get(url);
}

export const reportAbuseAuthor=async (post_id)=>{
    let author_id = Auth.user().user_id;
    let url=process.env.REACT_APP_APP_URL + '/report-abuse-article/'+post_id+'/'+author_id;
    return ssoInstance.get(url);
}

export const deletePost=async (post_id)=>{
    let url=process.env.REACT_APP_APP_URL + '/article/'+post_id;
    return ssoInstance.delete(url);
}
