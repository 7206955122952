import {connect} from 'react-redux';

import AuthorDetailComponent from '../component/AuthorDetailComponent';
import {
    getQuickArticleList,
    showAuthorById
} from "../../../actions/action";
import {posts, quickSaveArticle, showAuthorDetail} from "../../../actions";
import {showError} from "../../../actions/Error";

const mapStateToProps = state => {
    return{
        posts:state.article.posts,
        quicksaveArticle:state.article.quicksaveArticle,
        author:state.author.author
    }
}

const mapDispatchToProps = dispatch => {

    return {
        showAuthorById:(author_id,type)=>{
            if (type==='remove'){
                dispatch(posts(null))
            }
            showAuthorById(author_id)
                .then((response)=>{
                    dispatch(showAuthorDetail(response.data.author_details))
                    dispatch(posts(response.data.author_post))
                }).catch((error)=>{
                    let sources = error.response;
                    dispatch(showAuthorDetail([]))
                    showError(error)
                })
        },
        getQuickArticleList:(type)=>{
            if (type==='remove'){
                dispatch(quickSaveArticle([]))
            }
            getQuickArticleList()
                .then((response)=>{
                    dispatch(quickSaveArticle(response.data))
                }).catch((error)=>{
                    dispatch(quickSaveArticle([]))
                    showError(error)
                })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorDetailComponent);
