import React, { Component } from 'react';
import TopBar from "../../../components/topBar";
import {Auth} from 'gg-react-utilities';
import LoadingComponent from "../../../components/LoadingComponent";
import EmptyComponent from "../../../components/EmptyComponent";
import {ButtonFollow} from "../../../components/ButtonPublish";

class FollowTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            author_id:0,
            title:'Following'
        }
    }

    componentDidMount() {
        try {
            let author_id = this.props.match.params.author_id;
            this.setState({
                author_id:author_id,
                title:"Following",
            },()=>{
                this.getfollowerListById()
            })
        }catch (e) {
            this.props.history.goBack()
        }
    }

    getfollowerListById=()=>{
        let author_id = this.state.author_id;

        if (author_id){
            this.props.followerList(author_id)
        }else {
            this.props.history.goBack()
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedTagOption:selectedOption });
    }

    render() {
        if (this.props.follower===null) {
            return (
                <LoadingComponent {...this.props}/>
            );
        }
        if (this.props.follower.length<=0) {
            return (
                <div className="container">
                    <TopBar hideLogo={true} title={this.state.title} {...this.props} />
                    <EmptyComponent
                        message={"Following Not Available"}
                        title={"Swatantra"}
                        {...this.props}
                    />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar hideLogo={true} title={this.state.title} {...this.props} />
                    <div className="row mt-3">
                        {
                            this.props.follower.map((value, index)=>{
                                return(
                                        <div className="col-md-6 col-sm-6 col-xs-12 mb-3 mt-3" key={index}>
                                            <div className="border-menu p-3 mb-4 clearfix">
                                                <div className="float-left">
                                                    <img
                                                        src={value.Author_Image}
                                                        className="user-menu-img d-inline-block" />
                                                    <p className="follow-swa d-inline-block">{value.display_name}</p>
                                                </div>
                                                {
                                                    value.id===Auth.user().user_id?
                                                        ''
                                                        :
                                                        <ButtonFollow
                                                            className="btn follow-btn follow-swa float-right"
                                                            loading={false}
                                                            user_following={value.is_follow}
                                                            author_id={value.id}
                                                            getPostArticleData={this.getfollowerListById}
                                                        />
                                                }
                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        )
    }
}

export default FollowTagComponent;
