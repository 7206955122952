import React,{ Component } from 'react';
import TagArticleViewContainer from './container/TagArticleViewContainer';

export default class TagArticleView extends Component{
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render(){

        return(
            <React.Fragment>
                <TagArticleViewContainer {...this.props} />
            </React.Fragment>

        )
    }
}
