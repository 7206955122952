// import React, {Component} from 'react';
import {connect} from 'react-redux';

import ArticleCreateComponent from '../component/ArticleCreateComponent';
import {getArticleDetailById, addPost, getTagList} from "../../../actions/action";
import {setArticleDetailById} from "../../../actions";

const mapStateToProps = state => {

    return{
        articleDetail:state.article.articleDetail
    }
}

const mapDispatchToProps = dispatch => {

    return {
        addPost:(data)=>{
            return  addPost(data)
        },
        getTagList:()=>{
            return getTagList();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCreateComponent);
