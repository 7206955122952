import React, {Component} from 'react';
import TopBar from "../../../components/topBar";
import LoaderImg from '../../../images/100x100-Loader.gif';
import add from '../../../images/fab copy.svg';
import PostList from "../../Home/component/postList";
import LoadingComponent from "../../../components/LoadingComponent";
import EmptyComponent from "../../../components/EmptyComponent";
import Container from "../../../components/Container";
import CreateArticle from "../../../components/CreateArticle";
import ArticleContainer from "../../../container/ArticleContainer";

class AuthorDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            tag_name:null
        }
    }

    componentDidMount(){
        try {
            let tag_name = this.props.location.state.tag_name;
            this.setState({
                tag_name:tag_name
            },()=>{
                this.props.showAuthorById(this.state.tag_name,'remove')
            })
        }catch (e) {
            this.props.history.push('/')
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location.state.tag_name !== this.props.location.state.tag_name){
            try {
                let tag_name = nextProps.location.state.tag_name;
                this.setState({
                    tag_name:tag_name
                },()=>{
                    this.props.showAuthorById(this.state.tag_name,'remove')
                })
            }catch (e) {
                nextProps.history.push('/')
            }
        }
    }

    getTagArticleData=(type='update')=>{
        let tag_name = this.state.tag_name;
        if (tag_name){
            console.log(type)
            this.props.showAuthorById(tag_name,type)
        }
    }

    render(){
        if (this.props.posts === null){
            return (
                <LoadingComponent {...this.props}/>
            );
        }

        return (
            <React.Fragment>
                <div className="container">
                    <TopBar {...this.props} />
                    <div className="mt-5 mb-5">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <CreateArticle {...this.props}/>
                                    <ArticleContainer {...this.props}/>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 mt-2" >

                                    <PostList
                                        getData={this.getTagArticleData}
                                        auth={false}
                                        {...this.props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        );
    }
}

export default AuthorDetailComponent;
