import React, {Component} from 'react';
import {Auth} from 'gg-react-utilities';
import TopBar from "../../../components/topBar";
import LoadingWithOutTopBarComponent from "../../../components/LoadingWithOutTopBarComponent";
import {ButtonFollow} from "../../../components/ButtonPublish";

class AuthorDetail extends Component {
    render() {
        if (this.props.author===null){
            return (
                <LoadingWithOutTopBarComponent/>
            )
        }

        return (
            <React.Fragment>
                <div className="ptb-20">
                    <div className="">
                        <div className="bitmap">
                            <div className="mask-user">
                                {
                                    this.props.author.profile_pic ?
                                        <img src={this.props.author.profile_pic}
                                             className="user"/>
                                        :
                                        <img src="https://s3.ap-south-1.amazonaws.com/gg-statics/gg-statics-staging/header-icon/user.svg"
                                             className="user"/>
                                }
                                <h2 className="user-name">{this.props.author.name}</h2>
                                {/*<p className="be-zeddi">#{this.props.author.display_name}</p>*/}
                                {
                                    this.props.author.sso_user_id===Auth.user().user_id?
                                        <p className="be-zeddi">
                                            <a href={"/tag-following-list/"+this.props.author.sso_user_id+'/'+this.props.author.display_name} className="follow-count-link mr-2">{this.props.author.user_follow_count} Following</a>
                                            <a href={"/view-following/"+this.props.author.sso_user_id+'/'+this.props.author.display_name} className="follow-count-link">{this.props.author.user_follower_count} Followers</a>
                                        </p>
                                        :
                                        ''
                                }
                                {
                                    this.props.author.sso_user_id!==Auth.user().user_id?
                                        <ButtonFollow
                                            loading={false}
                                            user_following={this.props.author.user_following}
                                            author_id={this.props.author.sso_user_id}
                                            getPostArticleData={this.props.getAuthorArticleData}
                                        />
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-list-menu"  />
            </React.Fragment>
        );
    }
}

export default AuthorDetail;
