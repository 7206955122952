import React,{ Component } from 'react';
import QuickSaveArticleContainer from './container/QuickSaveArticleContainer';

export default class QuickSaveArticle extends Component{
    componentDidMount() {
        window.scrollTo(0,0)
    }
    render(){

        return(
            <React.Fragment>
                <QuickSaveArticleContainer {...this.props} />
            </React.Fragment>

        )
    }
}
