import React, {Component} from 'react';
import BOOKMARKERROR from "../images/bookmark.svg";

class EmptyComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"container"}>
                <center>
                    <img src={BOOKMARKERROR}
                         alt="error" className="errorImg img-fluid"/>
                    <h2 className="mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3">{this.props.message?this.props.message:"No Article Post Available"}</h2>
                </center>
            </div>
        );
    }
}

export default EmptyComponent;
